
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from "react-redux";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

const StyledMenu = styled((props) => <Menu  {...props} />)(
    ({ theme }) => ({
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.custom.text
        }
    }),
);
const StylePageSpan = styled((props) => <span  {...props} />)(
    ({ theme }) => ({
        color: theme.palette.custom.text,
        fontSize: '40px'
    }),
);
function HomePagesMenu({ pageName }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const organziation = useSelector(({ organziation }) => organziation);
    const [machineDeviceName, setPageDeviceName] = useState(pageName ? pageName : 'Safety');
    const [anchorPage, setAnchorPage] = useState(null);

    const handleOpenPageMenu = (event) => {
        setAnchorPage(event.currentTarget);
    };
    const handleClosePageMenu = () => {
        setAnchorPage(null);
    };
    const handleChoosePage = (item) => {
        setPageDeviceName(item.label);
        navigate(`/${item.redirectLink}`)
    };
    const pagesList = [
        { id: 0, label: 'Safety', redirectLink: "safety", icon: <VerifiedUserOutlinedIcon sx={{ color: theme.palette.custom.borderColor }} /> },
        { id: 1, label: 'Productivity', redirectLink: "productivity", icon: <AnalyticsOutlinedIcon sx={{ color: theme.palette.custom.borderColor }} />  },
        { id: 2, label: 'Maintenance', redirectLink: "maintenance", icon: <SettingsOutlinedIcon sx={{ color: theme.palette.custom.borderColor }} />  },
        { id: 3, label: 'Monitoring', redirectLink: "monitoring", icon: <EqualizerOutlinedIcon sx={{ color: theme.palette.custom.borderColor }} />  },
    ];
    return (
        <>
            <Tooltip title="Open settings">
                <Button variant="text" onClick={handleOpenPageMenu} endIcon={<KeyboardArrowDownIcon sx={{ fontSize: '45px', color: theme.palette.custom.text }} />} >
                    <StylePageSpan >{machineDeviceName}</StylePageSpan>
                </Button>
            </Tooltip>
            <StyledMenu
                id="menu-appbar"
                anchorEl={anchorPage}
                open={Boolean(anchorPage)}
                onClose={handleClosePageMenu}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                {pagesList && pagesList.map((item) => !(item.id === 0 && (organziation?.uid === 19 || organziation?.uid === 92)) && (
                    <MenuItem key={item.id} onClick={() => handleChoosePage(item)}>
                        {item.icon} &nbsp; <Typography textAlign="center">{item ? t(item.label) : ""}</Typography>
                    </MenuItem>
                ))}
            </StyledMenu>
        </>
    );
}

export default HomePagesMenu;
