/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetGrafanaDashboardUID,
  GetMachineTypeIcon,
  GetDeviceImage,
  GetBatteryImage,
  GetSignalImage,
  GetSignalText,
  GetDeviceDateIcon,
  GetHDOPImage,
  GetLatestDate,
} from "../utils/systemInfo";

export const setDeviceData = createAsyncThunk(
  "device/setDeviceData",
  async (inputData, { dispatch, getState }) => {
    let RecordOPSUM = [];
    if (inputData === null) {
      return null;
    }
    RecordOPSUM = PopulateDataHomeOPSUM(
      inputData.json,
      inputData.machineTypeFilter,
      inputData.machineType
    );
    if (
      inputData.newSearchText &&
      inputData.newSearchText.length > 0 &&
      RecordOPSUM &&
      RecordOPSUM.length > 0
    ) {
      const searchText = inputData.newSearchText.toLowerCase();
      return RecordOPSUM.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(searchText)
        )
      );
    }
    return RecordOPSUM;
  }
);
function PopulateDataHomeOPSUM(
  jsonThisWeek,
  machineTypeFilter = "",
  machineTypeValue
) {
  let opsumDataObj = {
    MachineType: "",
    DeviceName: "",
    DeviceID: "",
    DeviceProfileID: "",
    DeviceStatus: "",
    DeviceLatestOPSUM: "",
    DeviceLatestData: "",
    DeviceLatestRaw: "",
    DeviceLastSeen: "",
    NamedLocationOriginal: "",
    GrafanaDashboardUID: "",
    DeviceType: "",
    DeviceTypeIcon: "",
    DeviceSerial: "",
    DeviceSerialHex: "",
    DeviceOEMIcon: "",
    DeviceOEM: "",
    DeviceIcon: "",
    BatteryLvl: "",
    BatteryTitle: "",
    BatteryImage: "",
    DevConnectivityImg: "",
    DevConnectivity: "",
    DevDataValue: "",
    DevDataValue2: "",
    DevDataIcon: "",
    DevDataName: "",
    DevicePID: "",
    DeviceTitleDataName: "",
  };
  opsumDataObj.DeviceName = jsonThisWeek.name;
  opsumDataObj.DeviceID = jsonThisWeek.device_uid;
  opsumDataObj.DeviceProfileID = jsonThisWeek.device_profile_id;
  opsumDataObj.DevicePID = jsonThisWeek.device_profile_id;
  opsumDataObj.DeviceSerial = jsonThisWeek.serial;
  opsumDataObj.DeviceSerialHex = Number(jsonThisWeek.serial)
    .toString(16)
    .toLocaleUpperCase();
  opsumDataObj.DeviceOEM = String(jsonThisWeek.oem);
  if (opsumDataObj.DeviceOEM.length === 0) opsumDataObj.DeviceOEM = "nerospec";
  opsumDataObj.DeviceOEMIcon = `${
    process.env.PUBLIC_URL
  }/images/oem/${opsumDataObj.DeviceOEM.replaceAll(" ", "")}_Dark.png`;

  // DeviceType = String(DevicesArray[index].profile.vehicle_type);
  // if (DeviceType == "") DeviceType = "Unknown";

  let MachineType = String(jsonThisWeek.machine_type);
  opsumDataObj.MachineType = MachineType;
  opsumDataObj.GrafanaDashboardUID = GetGrafanaDashboardUID(MachineType);

  let DeviceType = String(
    jsonThisWeek.vehicle_type !== ""
      ? jsonThisWeek.vehicle_type
      : jsonThisWeek.device_type
  );
  machineTypeValue = String(
    jsonThisWeek.vehicle_type !== "" && jsonThisWeek.vehicle_type !== undefined
      ? "Machine"
      : "Device"
  );
  // let DeviceType = String(jsonThisWeek.vehicle_type );
  opsumDataObj.DeviceType = String(DeviceType);
  if (DeviceType === "") opsumDataObj.DeviceType = "Unknown";
  const DeviceTypeIcon = GetMachineTypeIcon(DeviceType);
  opsumDataObj.DeviceTypeIcon = DeviceTypeIcon;

  let LastLocation = jsonThisWeek?.location?.named?.lookup?.name;
  if (LastLocation === undefined || LastLocation === "") {
    LastLocation = `Unknown`;
  }
  opsumDataObj.NamedLocationOriginal = LastLocation;
  switch (machineTypeValue.toLowerCase()) {
    case "machine":
    default:
      opsumDataObj.DeviceLastSeen = GetLatestDate([
        jsonThisWeek?.dst_last_opsum,
        jsonThisWeek?.latest_data_dst,
        jsonThisWeek?.dst_last_seen,
      ]);
      opsumDataObj.DeviceTitleDataName = "Machine";
      break;
    case "device":
      let BatteryLvl = "";
      opsumDataObj.DeviceLastSeen = GetLatestDate([
        jsonThisWeek.dst,
        jsonThisWeek.latest_data_dst,
        jsonThisWeek.dst_last_seen,
      ]);
      opsumDataObj.DeviceTitleDataName = "Device";
      opsumDataObj.DevConnectivityImg = GetSignalImage(
        jsonThisWeek.iot_summary?.payload?.link_quality
      );
      opsumDataObj.DevConnectivity = GetSignalText(
        jsonThisWeek.iot_summary?.payload?.link_quality
      );
      switch (jsonThisWeek.device_type) {
        case "tortoise":
          BatteryLvl = jsonThisWeek.iot_summary?.payload?.battery_level;
          if (BatteryLvl < 0) BatteryLvl = 0;
          if (BatteryLvl > 100) BatteryLvl = 100;
          opsumDataObj.BatteryTitle = "BATTERY LEVEL";
          opsumDataObj.BatteryImage = GetBatteryImage(BatteryLvl);
          opsumDataObj.BatteryLvl = BatteryLvl + " %";
          opsumDataObj.DevDataIcon = GetDeviceDateIcon(
            "movement_detected_icon"
          );
          opsumDataObj.DevDataName = "MOVEMENT DETECTED";
          opsumDataObj.DevDataValue = "NO";
          if (jsonThisWeek.iot_summary?.payload?.movement_detect_b === 1) {
            opsumDataObj.DevDataValue = `YES`;
          }
          opsumDataObj.DevDataSecondValue = "NO";
          opsumDataObj.DevDataSecondIcon =
            GetDeviceDateIcon("gps_lock_alert_no");
          if (jsonThisWeek.iot_summary?.payload?.gps_lock_b === 1) {
            opsumDataObj.DevDataSecondValue = `YES`;
            opsumDataObj.DevDataSecondIcon =
              GetDeviceDateIcon("gps_lock_alert_yes");
          }
          opsumDataObj.DevDataSecondName = "GPS Lock Alert";
          opsumDataObj.DevDataSecondIcon = GetDeviceDateIcon("location_pin"); // Temp use until real svg available

          // ===========================================================

          opsumDataObj.DevDataThirdValue = "NO";
          opsumDataObj.DevDataThirdIcon =
            GetDeviceDateIcon("gps_used_alert_no");
          if (jsonThisWeek.iot_summary?.payload?.gps_used_b === 1) {
            opsumDataObj.DevDataThirdValue = `YES`;
            opsumDataObj.DevDataThirdIcon =
              GetDeviceDateIcon("gps_used_alert_yes");
          }
          opsumDataObj.DevDataThirdName = "GPS Used Alert";
          opsumDataObj.DevDataThirdIcon = GetDeviceDateIcon("location_pin"); // Temp use until real svg available
          // ===========================================================

          opsumDataObj.DevDataFourthValue = "NO";
          opsumDataObj.DevDataFourthIcon = GetDeviceDateIcon("nco_ok_b_no");
          if (jsonThisWeek.iot_summary?.payload?.cno_ok_b === 1) {
            opsumDataObj.DevDataFourthValue = `YES`;
            opsumDataObj.DevDataFourthIcon = GetDeviceDateIcon("nco_ok_b_yes");
          }
          opsumDataObj.DevDataFourthName = "Cn OK Alert";
          opsumDataObj.DevDataFourthIcon = GetDeviceDateIcon("location_pin"); // Temp use until real svg available
          // ===========================================================

          opsumDataObj.DevDataFifthValue = `${jsonThisWeek.iot_summary?.payload?.hdop} hdop`;
          opsumDataObj.DevDataFifthIcon = GetHDOPImage(
            jsonThisWeek.iot_summary?.payload?.hdop
          );
          opsumDataObj.DevDataFifthName = "GPS Signal Strength";

          break;
        case "nngen2_leak":
          BatteryLvl = (
            jsonThisWeek.iot_summary?.payload?.battery_voltage / 1000
          ).toFixed(2);
          if (BatteryLvl < 0) BatteryLvl = 0;
          opsumDataObj.BatteryTitle = "BATTERY VOLTAGE";
          opsumDataObj.BatteryImage = GetBatteryImage(BatteryLvl);
          opsumDataObj.BatteryLvl = BatteryLvl + " V";
          opsumDataObj.DevDataValue = "WET";
          opsumDataObj.DevDataIcon = GetDeviceDateIcon("dropwet");
          opsumDataObj.DevDataName = "DETECTION";

          if (jsonThisWeek.iot_summary?.payload?.liquid_detect_b === 0) {
            opsumDataObj.DevDataValue = "DRY";
            opsumDataObj.DevDataIcon = GetDeviceDateIcon("dropdry");
          }
          break;
        case "nngen2_flood":
          BatteryLvl = (
            jsonThisWeek.iot_summary?.payload?.battery_voltage / 1000
          ).toFixed(2);
          if (BatteryLvl < 0) BatteryLvl = 0;
          opsumDataObj.BatteryTitle = "BATTERY VOLTAGE";
          opsumDataObj.BatteryImage = GetBatteryImage(BatteryLvl);
          opsumDataObj.BatteryLvl = BatteryLvl + " V";
          opsumDataObj.DevDataValue = "WET";
          opsumDataObj.DevDataIcon = GetDeviceDateIcon("dropwet");
          opsumDataObj.DevDataName = "DETECTION";

          if (jsonThisWeek.iot_summary?.payload?.liquid_detect_b === 0) {
            opsumDataObj.DevDataValue = "DRY";
            opsumDataObj.DevDataIcon = GetDeviceDateIcon("dropdry");
          }
          break;
        case "nngen2_temperature":
        case "nngen2_temp":
          BatteryLvl = (
            jsonThisWeek.iot_summary?.payload?.battery_voltage / 1000
          ).toFixed(2);
          if (BatteryLvl < 0) BatteryLvl = 0;
          opsumDataObj.BatteryTitle = "BATTERY VOLTAGE";
          opsumDataObj.BatteryImage = GetBatteryImage(BatteryLvl);
          opsumDataObj.BatteryLvl = BatteryLvl + " V";
          opsumDataObj.DevDataIcon = GetDeviceDateIcon("temperature");
          opsumDataObj.DevDataName = "TEMPERATURE";
          opsumDataObj.DevDataValue = `
          Probe: ${jsonThisWeek.iot_summary?.payload?.external_temperature.toFixed(
            2
          )} °C | ${(
            jsonThisWeek.iot_summary?.payload?.external_temperature * (9 / 5) +
            32
          ).toFixed(2)} °F
         `;
          opsumDataObj.DevDataValue2 = `
          Ambient: ${jsonThisWeek.iot_summary?.payload?.internal_temperature.toFixed(
            2
          )} °C | ${(
            jsonThisWeek.iot_summary?.payload?.internal_temperature * (9 / 5) +
            32
          ).toFixed(2)} °F
        `;
          break;
        case "nngen2_tempvibe":
          BatteryLvl = (
            jsonThisWeek.iot_summary?.payload?.battery_voltage / 1000
          ).toFixed(2);
          if (BatteryLvl < 0) BatteryLvl = 0;
          opsumDataObj.BatteryTitle = "BATTERY VOLTAGE";
          opsumDataObj.BatteryImage = GetBatteryImage(BatteryLvl);
          opsumDataObj.BatteryLvl = BatteryLvl + " V";
          opsumDataObj.DevDataIcon = GetDeviceDateIcon("temperature");
          opsumDataObj.DevDataName = "TEMPERATURE";
          opsumDataObj.DevDataValue = `
          Probe: ${jsonThisWeek.iot_summary?.payload?.external_temperature.toFixed(
            2
          )} °C | ${(
            jsonThisWeek.iot_summary?.payload?.external_temperature * (9 / 5) +
            32
          ).toFixed(2)} °F
         `;
          opsumDataObj.DevDataValue2 = `
          Ambient: ${jsonThisWeek.iot_summary?.payload?.internal_temperature.toFixed(
            2
          )} °C | ${(
            jsonThisWeek.iot_summary?.payload?.internal_temperature * (9 / 5) +
            32
          ).toFixed(2)} °F
        `;
          break;
        case "nngen2_level":
          BatteryLvl = (
            jsonThisWeek.iot_summary?.payload?.battery_voltage / 1000
          ).toFixed(2);
          if (BatteryLvl < 0) BatteryLvl = 0;
          opsumDataObj.BatteryTitle = "BATTERY VOLTAGE";
          opsumDataObj.BatteryImage = GetBatteryImage(BatteryLvl);
          opsumDataObj.BatteryLvl = BatteryLvl + " V";
          opsumDataObj.DevDataIcon = GetDeviceDateIcon("volume");
          opsumDataObj.DevDataName = "VOLUME";
          let TankHeight =
            jsonThisWeek.iot_summary?.payload?.level_reading_at_present > 4040
              ? 15000
              : 4040;
          let TankPercentage = (
            ((TankHeight -
              jsonThisWeek.iot_summary?.payload?.level_reading_at_present) /
              TankHeight) *
            100
          ).toFixed(2);
          opsumDataObj.DevDataValue =
            TankPercentage +
            " % | " +
            jsonThisWeek.iot_summary?.payload?.level_reading_at_present +
            " mm from top";
          break;
        case "nngen2_flow":
          BatteryLvl = (
            jsonThisWeek.iot_summary?.payload?.battery_voltage / 1000
          ).toFixed(2);
          if (BatteryLvl < 0) BatteryLvl = 0;
          opsumDataObj.BatteryTitle = "BATTERY VOLTAGE";
          opsumDataObj.BatteryImage = GetBatteryImage(BatteryLvl);
          opsumDataObj.BatteryLvl = BatteryLvl + " V";
          opsumDataObj.DevDataIcon = GetDeviceDateIcon("volume");
          opsumDataObj.DevDataName = "LITERS PER HOUR";
          opsumDataObj.DevDataValue =
            jsonThisWeek.iot_summary?.payload?.volume_since_last_tx + " L/h";
          break;

        default:
          BatteryLvl = (
            jsonThisWeek.iot_summary?.payload?.battery_voltage / 1000
          ).toFixed(2);
          if (BatteryLvl < 0) BatteryLvl = 0;
          opsumDataObj.BatteryTitle = "BATTERY VOLTAGE";
          opsumDataObj.BatteryImage = GetBatteryImage(BatteryLvl);
          opsumDataObj.BatteryLvl = BatteryLvl + " V";

          break;
      }
      opsumDataObj.DeviceIcon = GetDeviceImage(DeviceType);
      break;
  }
  return opsumDataObj;
}

const initialState = null;

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDeviceData.fulfilled, (state, action) => action.payload);
  },
});

export const selectDevice = ({ device }) => device;

export default deviceSlice.reducer;
