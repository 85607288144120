/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetGrafanaDashboardUID,
  GetMachineTypeIcon,
} from "../utils/systemInfo";

export const setMachineSafetyData = createAsyncThunk('machineSafety/setMachineSafetyData', async (inputData, { dispatch, getState }) => {
  let RecordOPSUM = [];
  if (inputData === null) {
    return null
  }
  RecordOPSUM = PopulateDataHomeOPSUM(inputData.jsonCurrent,
    inputData.jsonPrevious,
    inputData.machineTypeFilter,
  )
  if (inputData.newSearchText && inputData.newSearchText.length > 0 && RecordOPSUM && RecordOPSUM.length > 0) {
    const searchText = inputData.newSearchText.toLowerCase();
        return RecordOPSUM.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );

  }
  return RecordOPSUM;
});
function PopulateDataHomeOPSUM(jsonThisWeek, jsonLastWeek, machineTypeFilter = "") {

  const deviceRawObject = jsonThisWeek.opsum.device[0];
  let opsumDataObj = {
    DataCached: false,
    MachineType: "",
    TotalSBICrawlMinutes: 0,
    TotalSBIStopMinutes: 0,
    TotalCWSCrawlMinutes: 0,
    TotalCWSBrakeMinutes: 0,
    TotalSBICrawlCounter: 0,
    TotalSBIStopCounter: 0,
    TotalCWSCrawlCounter: 0,
    TotalCWSBrakeCounter: 0,
    ActiveDevicesCount: 0,
    InactiveDevicesCount: 0,
    TotalSBICrawlMinutesLastWeek: 0,
    TotalSBIStopMinutesLastWeek: 0,
    TotalCWSCrawlMinutesLastWeek: 0,
    TotalCWSBrakeMinutesLastWeek: 0,
    TotalSBICrawlCounterLastWeek: 0,
    TotalSBIStopCounterLastWeek: 0,
    TotalCWSCrawlCounterLastWeek: 0,
    TotalCWSBrakeCounterLastWeek: 0,
    DeviceName: "",
    DeviceID: "",
    DeviceLastSeen: "",
    GrafanaDashboardUID: "",
    DeviceType: "",
    DeviceTypeIcon: "",
    DeviceTitleDataName: "Machine",
  };
  opsumDataObj.DeviceName = deviceRawObject.profile.name;
  opsumDataObj.DeviceID = deviceRawObject.profile.device_uid;
  const DeviceLatestData = deviceRawObject.profile.latest_data_dst;
  opsumDataObj.DeviceLastSeen = deviceRawObject.profile.dst_last_seen;
  if (
    new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
    opsumDataObj.DeviceLastSeen.length === 0
  ) {
    opsumDataObj.DeviceLastSeen = DeviceLatestData;
    if (
      new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
      opsumDataObj.DeviceLastSeen.length === 0
    )
      opsumDataObj.DeviceLastSeen = " not seen";
  }

  // DeviceType = String(DevicesArray[index].profile.vehicle_type);
  // if (DeviceType == "") DeviceType = "Unknown";

  let MachineType = String(deviceRawObject.profile.machine_type);
  opsumDataObj.GrafanaDashboardUID = GetGrafanaDashboardUID(MachineType);

  let DeviceType = String(deviceRawObject.profile.vehicle_type);
  if (DeviceType === "") opsumDataObj.DeviceType = "Unknown";
  const DeviceTypeIcon = GetMachineTypeIcon(DeviceType);
  opsumDataObj.DeviceTypeIcon = DeviceTypeIcon;

  const MachineSafetyJson = deviceRawObject.safety;
  for (let i in MachineSafetyJson) {
    switch (MachineSafetyJson[i].event_type) {
      case "SBI":
        for (let j in MachineSafetyJson[i].value) {
          switch (MachineSafetyJson[i].value[j].event) {
            case "CRAWL":
              opsumDataObj.TotalSBICrawlMinutes =
                MachineSafetyJson[i].value[j].timer;
              opsumDataObj.TotalSBICrawlCounter =
                MachineSafetyJson[i].value[j].counter;
              break;
            case "STOP":
              opsumDataObj.TotalSBIStopMinutes =
                MachineSafetyJson[i].value[j].timer;
              opsumDataObj.TotalSBIStopCounter =
                MachineSafetyJson[i].value[j].counter;
              break;
            default:
              break;
          }
        }
        break;
      case "CWS":
        for (let j in MachineSafetyJson[i].value) {
          switch (MachineSafetyJson[i].value[j].event) {
            case "CRAWL":
              opsumDataObj.TotalCWSCrawlMinutes =
                MachineSafetyJson[i].value[j].timer;
              opsumDataObj.TotalCWSCrawlCounter =
                MachineSafetyJson[i].value[j].counter;
              break;
            case "BRAKE":
              opsumDataObj.TotalCWSBrakeMinutes =
                MachineSafetyJson[i].value[j].timer;
              opsumDataObj.TotalCWSBrakeCounter =
                MachineSafetyJson[i].value[j].counter;
              break;
            default:
              break;
          }
        }
        break;
      default:
        break;
    }
  }
  if (
    jsonLastWeek &&
    jsonLastWeek.opsum &&
    jsonLastWeek.opsum.device &&
    jsonLastWeek.opsum.device.length > 0
  ) {
    const deviceRawObjectLastWeek = jsonLastWeek.opsum.device[0];

    const DeviceNameLastWeek = deviceRawObjectLastWeek.profile.name;
    if (opsumDataObj.DeviceName === DeviceNameLastWeek) {
      // let Table_Row = table.insertRow(-1);
      const MachineSafetyJson = deviceRawObjectLastWeek.safety;

      for (let i in MachineSafetyJson) {
        switch (MachineSafetyJson[i].event_type) {
          case "SBI":
            for (let j in MachineSafetyJson[i].value) {
              switch (MachineSafetyJson[i].value[j].event) {
                case "CRAWL":
                  opsumDataObj.TotalSBICrawlMinutesLastWeek =
                    MachineSafetyJson[i].value[j].timer;
                  opsumDataObj.TotalSBICrawlCounterLastWeek =
                    MachineSafetyJson[i].value[j].counter;
                  break;
                case "STOP":
                  opsumDataObj.TotalSBIStopMinutesLastWeek =
                    MachineSafetyJson[i].value[j].timer;
                  opsumDataObj.TotalSBIStopCounterLastWeek =
                    MachineSafetyJson[i].value[j].counter;
                  break;
                default:
                  break;
              }
            }
            break;
          case "CWS":
            for (let j in MachineSafetyJson[i].value) {
              switch (MachineSafetyJson[i].value[j].event) {
                case "CRAWL":
                  opsumDataObj.TotalCWSCrawlMinutesLastWeek =
                    MachineSafetyJson[i].value[j].timer;
                  opsumDataObj.TotalCWSCrawlCounterLastWeek =
                    MachineSafetyJson[i].value[j].counter;
                  break;
                case "BRAKE":
                  opsumDataObj.TotalCWSBrakeMinutesLastWeek =
                    MachineSafetyJson[i].value[j].timer;
                  opsumDataObj.TotalCWSBrakeCounterLastWeek =
                    MachineSafetyJson[i].value[j].counter;
                  break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      }
    }
  }
  return opsumDataObj;
}

const initialState = null;

const machineSafetySlice = createSlice({
  name: "machineSafety",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setMachineSafetyData.fulfilled, (state, action) => action.payload);
  },
});

export const selectMachineSafety = ({ machineSafety }) => machineSafety;

export default machineSafetySlice.reducer;
