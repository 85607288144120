/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setExceptionsData = createAsyncThunk('exceptions/setExceptionsData', async (inputData, { dispatch, getState }) => {
  let exceptions = [];
  if (inputData === null) {
    return null
  }
  exceptions = PopulateExceptions(inputData.json)
  if (inputData.newSearchText && inputData.newSearchText.length > 0 && exceptions && exceptions.length > 0) {
    const searchText = inputData.newSearchText.toLowerCase();
        return exceptions.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );

  }
  return exceptions;
});
function PopulateExceptions(json) {
  let exception = [];
  const ExceptionsArray = json.scheduled_time_exception;
  for (let index in ExceptionsArray) {
    let exceptionsDataObj = {
        "scheduled_time_exception_id": "",
        "scheduled_time_config_id": "",
        "name":"",
        "description": "",
        "datetime_start":{
          "f":"",
          "t":"",
        },
        "datetime_end":{
          "f":"",
          "t":"",
        },
        "status": ""
    }
    exceptionsDataObj.scheduled_time_exception_id = ExceptionsArray[index].scheduled_time_exception_id;
    exceptionsDataObj.scheduled_time_config_id = ExceptionsArray[index].scheduled_time_config_id;
    exceptionsDataObj.name = ExceptionsArray[index].name;
    exceptionsDataObj.datetime_start = ExceptionsArray[index].datetime_start;
    exceptionsDataObj.datetime_end = ExceptionsArray[index].datetime_end;
    exceptionsDataObj.description = ExceptionsArray[index].description;
    exceptionsDataObj.status = ExceptionsArray[index].status;
    exception.push(exceptionsDataObj)
  }
  return exception
};

const initialState = null


const exceptionsSlice = createSlice({
  name: 'exceptions',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setExceptionsData.fulfilled, (state, action) => action.payload);
  },
});

export const selectExceptions = ({ exceptions }) => exceptions;

export default exceptionsSlice.reducer;
