/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setOrganization = createAsyncThunk('organization/setOrganization', async (organization, { dispatch, getState }) => {

  return organization;
});



const initialState = null


const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setOrganization.fulfilled, (state, action) => action.payload);
  },
});

export const selectOrganization = ({ organization }) => organization;

export default organizationSlice.reducer;
