import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { capitalize } from '@mui/material';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";

// import HomePagesMenu from '../common/HomePagesMenu';
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import OrganizationsTableRow from "./organizationsTableRow";
import { setOrganizations } from "../store/organziationsSlice";
import Breadcrumbs from "../common/breadcrumbs";
import SearchInput from "../common/searchInput";
import { useNavigate } from "react-router-dom";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV";
import StyledTypography from "../common/StyledTypography";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Organizations", href: "organizations" },
];

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const headCells = [
  {
    id: "dropDown",
    numeric: false,
    disablePadding: true,
    label: "",
    sort: true,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "emailAddress",
    numeric: false,
    disablePadding: false,
    label: "Email Address",
    sort: true,
  },
  {
    id: "mobileNumber",
    numeric: false,
    disablePadding: false,
    label: "MobileNumber",
    sort: true,
  },
  {
    id: "website",
    numeric: false,
    disablePadding: false,
    label: "Website",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
function Organizations() {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organziations = useSelector(({ organziations }) => organziations);
  const [organizationsInputData, setOrganizationsInputData] =
    useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 10
  );
  const [searchText, setSearchText] = useState("");

  const [selected, setSelected] = useState([]);
  const [loadingValue, setLoadingValue] = useState(true);

  useEffect(() => {
    //   let currentorganization;
    // if (organziation && organziation !== currentorganization) {
    //  currentorganization = organziation;
    setOrganizationsInputData(null);
    const userObj = JSON.parse(localStorage.getItem("user"));
    const organizationsArray = userObj.organizations;
    setOrganizationsInputData({
      jsonCurrent: organizationsArray,
      newSearchText: "",
    });
    dispatch(
      setOrganizations({ jsonCurrent: organizationsArray, newSearchText: "" })
    );
    setLoadingValue(false);
    //   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  }, [organziation]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // navigate(`/device?device=${uid}&DeviceTitleDataName=${DeviceTitleDataName}`)
    setSelected(newSelected);
  };
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let opsumInputObj = organizationsInputData;
    opsumInputObj.newSearchText = newSearchText;
    dispatch(setOrganizations(opsumInputObj));
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (organziations && organziations.length > 0) {
        return stableSort(organziations, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, organziations]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              width: "100%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ width: "100%" }}>
              {
                //<HomePagesMenu pageName={'Maintenance'} />
              }
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <Typography
                sx={{
                  fontSize: "45px",
                  mb: 0,
                  color: theme.palette.custom.text,
                }}
                variant="h2"
                gutterBottom
              >
                {
                  // {capitalize(t('Devices'))}
                }
                {t("Organizations")}
              </Typography>
            </Box>
          </Box>
          <Button
            variant="contained"
            sx={{
              height: "36px",
              backgroundColor: theme.palette.custom.buttonColor,
              color: theme.palette.custom.buttonTextColor,
              "&:hover": {
                backgroundColor: theme.palette.custom.buttonHoverColor,
                color: theme.palette.custom.buttonHoverTextColor,
                boxShadow: "none",
              },
              float: "right",
              width: "min-content",
              whiteSpace: "nowrap",
              padding: "1rem",
            }}
            onClick={() => {
              navigate("/organizationCreate");
            }}
          >
            {t("Create New Organization")}
          </Button>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <SearchInput
        searchText={searchText}
        handleSearch={handleSearch}
        autoFocus
        sx={{
          width: { xs: "100%", sm: "20%" },
          borderColor: theme.palette.custom.borderColor,
          borderRadius: "11px",
          backgroundColor: theme.palette.custom.light,
        }}
      />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              hideCheck
            />

            <TableBody>
              {organziations &&
              organziations.length > 0 &&
              visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <OrganizationsTableRow
                      key={index}
                      row={row}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={organziations ? organziations.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom.buttonColor,
          color: theme.palette.custom.buttonTextColor,
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
          float: "right",
          width: "min-content",
          whiteSpace: "nowrap",
          padding: "1rem",
        }}
        onClick={() =>
          exportTableToCSV(headCells, visibleRows, "Organizations List")
        }
      >
        {t("Download List (*.csv)")}
      </Button>
    </Box>
  );
}

export default Organizations;
