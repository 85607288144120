import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ThemesService from "../api/themes";
import React from "react";
import { setTheme } from "../store/themeSlice";
import { styled } from "@mui/material/styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#013C59",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#f37721",
  },
}));

export const ColorModeContext = React.createContext({
  toggleColorMode: () => { },
});

export default function Theme({ children }) {
  const [mode, setMode] = useState(
    localStorage.getItem("color_theme") === "dark" ? "dark" : "light"
  );
  const organziation = useSelector(({ organziation }) => organziation);
  const [loading, setLoadingValue] = useState(true);
  const dispatch = useDispatch();
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: (currentThemeagaian) => {
        dispatch(setTheme(currentThemeagaian === "light" ? "dark" : "light"));
        localStorage.setItem(
          "color_theme",
          currentThemeagaian === "light" ? "dark" : "light"
        );
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [themeLight, setThemeLight] = useState(
    localStorage.getItem("themeLightPalette")
      ? JSON.parse(localStorage.getItem("themeLightPalette"))
      : {
        primary: { main: "#f47721" },
        secondary: {
          main: "#161719",
        },
        custom: {
          boxShadowColor: "#7c8798",
          text: "#fff",
          textLightColor: "#fff",
          textDarkColor: "#000",
          borderColor: "#e8c61b",
          buttonColor: "#066084",
          headerColor: "#066084",
          inputBackgroundColor: "#066084",
          buttonTextColor: "#066084",
          buttonHoverColor: "#066084",
          buttonHoverTextColor: "#066084",
          backgroundColor: "#066084",
          navigationBackgroundColor: "#161719",
          hexagonBackgroundCOlor: "#7C8798",
          navWidth: "200px",
        },
        typography: {
          fontFamily: `"Montserrat", "Rubik",  sans-serif`,
          fontheadingWeight: "bold",
        },
      }
  );
  const [themeDark, setThemeDark] = useState(
    localStorage.getItem("themeDarkPalette")
      ? JSON.parse(localStorage.getItem("themeDarkPalette"))
      : {
        primary: { main: "#f47721" },
        secondary: {
          main: "#161719",
        },
        custom: {
          boxShadowColor: "#7c8798",
          text: "#fff",
          textLightColor: "#fff",
          textDarkColor: "#000",
          borderColor: "#e8c61b",
          buttonColor: "#066084",
          headerColor: "#066084",
          inputBackgroundColor: "#066084",
          buttonTextColor: "#066084",
          buttonHoverColor: "#066084",
          buttonHoverTextColor: "#066084",
          backgroundColor: "#066084",
          navigationBackgroundColor: "#161719",
          hexagonBackgroundCOlor: "#7C8798",
          navWidth: "200px",
        },
        typography: {
          fontFamily: `"Montserrat", "Rubik",  sans-serif`,
          fontheadingWeight: "bold",
        },
      }
  );

  //  useEffect(() => {}, []);
  useEffect(() => {
    const defaultOrganization = localStorage.getItem("defaultOrganization");
    const orgObj = JSON.parse(defaultOrganization) || organziation;
    if (orgObj && orgObj.uid !== organziation?.uid) {
      const apiKey = orgObj.api_key_list[0].key;
      const uid = orgObj.uid;
      ThemesService.getThemeProfileLink({
        uid: uid,
        apiKey,
      })
        .then((linkJson) => {
          for (
            let index = 0;
            index < linkJson.theme_profile_link.length;
            index++
          ) {
            ThemesService.getThemeProfile({
              theme_profile_id:
                linkJson.theme_profile_link[index].theme_profile_id,
              apiKey,
            })
              .then((profileJson) => {
                if (
                  !profileJson.theme_profile[0].name
                    .toLowerCase()
                    .includes("dark")
                ) {
                  ThemesService.GetThemeProfileAttributes({
                    theme_attribute_id: "",
                    theme_profile_id: profileJson.theme_profile[0].theme_id,
                    name: "",
                    status: "",
                    apiKey,
                  })
                    .then((attributeJson) => {
                      if (
                        attributeJson &&
                        attributeJson.theme_profile_attributes &&
                        attributeJson.theme_profile_attributes.length > 0
                      ) {
                        const primaryColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "primary-color"
                          ).value;
                        const textLightColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "text-light-color"
                          ).value;
                        const textDarkColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "text-dark-color"
                          ).value;
                        const headerColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "header-color"
                          ).value;
                        const inputBackgroundColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "input-background-color"
                          ).value;
                        const backgroundColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "background-color"
                          ).value;
                        const backgroundColor2 =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "background-color2"
                          ).value;
                        const navigationBackgroundColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "nav-color"
                          ).value;
                        const buttonColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-color"
                          ).value;
                        const buttonTextColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-text-color"
                          ).value;
                        const buttonHoverColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-hover-color"
                          ).value;
                        const buttonHoverTextColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-hover-text-color"
                          ).value;
                        const boxShadowColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "box-shadow-color"
                          ).value;
                        const navWidthValue =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "nav-width"
                          ).value;
                        const inputTextColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "input-text-color"
                          ).value;
                        const themeLightPalette = {
                          primary: {
                            main: backgroundColor ? backgroundColor : "#f47721",
                          },
                          secondary: {
                            main: backgroundColor ? backgroundColor : "#161719",
                          },
                          custom: {
                            boxShadowColor: boxShadowColor
                              ? boxShadowColor
                              : "#7c8798",
                            text: textLightColor ? textLightColor : "#fff",
                            textLightColor: textLightColor
                              ? textLightColor
                              : "#fff",
                            textDarkColor: textDarkColor
                              ? textDarkColor
                              : "#000",
                            borderColor: primaryColor
                              ? primaryColor
                              : "#e8c61b",
                            buttonColor: buttonColor ? buttonColor : "#066084",
                            headerColor: headerColor ? headerColor : "#066084",
                            inputBackgroundColor: inputBackgroundColor
                              ? inputBackgroundColor
                              : "#066084",
                            inputTextColor: inputTextColor
                              ? inputTextColor
                              : "#fff",
                            buttonTextColor: buttonTextColor
                              ? buttonTextColor
                              : "#066084",
                            buttonHoverColor: buttonHoverColor
                              ? buttonHoverColor
                              : "#066084",
                            buttonHoverTextColor: buttonHoverTextColor
                              ? buttonHoverTextColor
                              : "#066084",
                            backgroundColor: backgroundColor
                              ? backgroundColor
                              : "#066084",
                            backgroundColor2: backgroundColor2
                              ? backgroundColor2
                              : "#066084",
                            navigationBackgroundColor: navigationBackgroundColor
                              ? navigationBackgroundColor
                              : backgroundColor2,
                            hexagonBackgroundCOlor: boxShadowColor
                              ? boxShadowColor
                              : "#7C8798",

                            navWidth: navWidthValue ? navWidthValue : "200px",
                          },
                        };
                        setLoadingValue(false);
                        // const lightthemeca =
                        //   localStorage.getItem("themeLightPalette");
                        localStorage.setItem(
                          "themeLightPalette",
                          JSON.stringify(themeLightPalette)
                        );
                        setThemeLight(themeLightPalette);
                      }
                    })
                    .catch((errorr) => {
                      if (process.env.NODE_ENV === 'development') console.log("themes error", errorr);
                    });
                } else {
                  ThemesService.GetThemeProfileAttributes({
                    theme_attribute_id: "",
                    theme_profile_id: profileJson.theme_profile[0].theme_id,
                    name: "",
                    status: "",
                    apiKey,
                  })
                    .then((attributeJson) => {
                      if (
                        attributeJson &&
                        attributeJson.theme_profile_attributes &&
                        attributeJson.theme_profile_attributes.length > 0
                      ) {
                        const primaryColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "primary-color"
                          ).value;
                        const textLightColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "text-light-color"
                          ).value;
                        const textDarkColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "text-dark-color"
                          ).value;
                        const headerColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "header-color"
                          ).value;
                        const inputBackgroundColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "input-background-color"
                          ).value;
                        const backgroundColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "background-color"
                          ).value;
                        const backgroundColor2 =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "background-color2"
                          ).value;
                        const navigationBackgroundColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "nav-color"
                          ).value;
                        const buttonColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-color"
                          ).value;
                        const buttonTextColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-text-color"
                          ).value;
                        const buttonHoverColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-hover-color"
                          ).value;
                        const buttonHoverTextColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "button-hover-text-color"
                          ).value;
                        const boxShadowColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "box-shadow-color"
                          ).value;
                        const navWidthValue =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "nav-width"
                          ).value;
                        const inputTextColor =
                          attributeJson.theme_profile_attributes.find(
                            (x) => x.name === "input-text-color"
                          ).value;
                        const themeDarkPalette = {
                          primary: {
                            main: primaryColor ? primaryColor : "#f47721",
                          },
                          secondary: {
                            main: backgroundColor2
                              ? backgroundColor2
                              : "#161719",
                          },
                          custom: {
                            boxShadowColor: boxShadowColor
                              ? boxShadowColor
                              : "#7c8798",
                            text: textLightColor ? textLightColor : "#fff",
                            textLightColor: textLightColor
                              ? textLightColor
                              : "#fff",
                            textDarkColor: textDarkColor
                              ? textDarkColor
                              : "#000",
                            borderColor: primaryColor
                              ? primaryColor
                              : "#e8c61b",
                            buttonColor: buttonColor ? buttonColor : "#066084",
                            headerColor: headerColor ? headerColor : "#066084",
                            inputBackgroundColor: inputBackgroundColor
                              ? inputBackgroundColor
                              : "#066084",
                            inputTextColor: inputTextColor
                              ? inputTextColor
                              : "#fff",
                            buttonTextColor: buttonTextColor
                              ? buttonTextColor
                              : "#066084",
                            buttonHoverColor: buttonHoverColor
                              ? buttonHoverColor
                              : "#066084",
                            buttonHoverTextColor: buttonHoverTextColor
                              ? buttonHoverTextColor
                              : "#066084",
                            backgroundColor: backgroundColor
                              ? backgroundColor
                              : "#066084",
                            backgroundColor2: backgroundColor2
                              ? backgroundColor2
                              : "#066084",
                            navigationBackgroundColor: navigationBackgroundColor
                              ? navigationBackgroundColor
                              : backgroundColor2,
                            hexagonBackgroundCOlor: boxShadowColor
                              ? boxShadowColor
                              : "#7C8798",
                            navWidth: navWidthValue ? navWidthValue : "200px",
                          },
                        };
                        // const lightthemeca =
                        //   localStorage.getItem("themeDarkPalette");
                        setLoadingValue(false);
                        localStorage.setItem(
                          "themeDarkPalette",
                          JSON.stringify(themeDarkPalette)
                        );
                        setThemeDark(themeDarkPalette);
                      }
                    })
                    .catch((errorr) => {
                      if (process.env.NODE_ENV === 'development') console.log("themes error", errorr);
                    });
                }
              })
              .catch((errorr) => {
                if (process.env.NODE_ENV === 'development') console.log("themes error", errorr);
              });
          }
          setLoadingValue(false);
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === 'development') console.log("themes error", errorr);
        });
    } else {
      setLoadingValue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "dark" ? themeDark : themeLight),
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
        typography: {
          fontFamily: `"Montserrat", "Rubik",  sans-serif`,
          fontheadingWeight: "bold",
          textSizeNormal: "0.9rem",
          button: {
            textTransform: "none",
          },
        },
      }),
    [mode, themeDark, themeLight]
  );
  const extendedThemeState = React.useMemo(
    () =>
      extendTheme({
        palette: {
          mode,
          ...(mode === "dark" ? themeDark : themeLight),
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
          },
        },
        typography: {
          fontFamily: `"Montserrat", "Rubik",  sans-serif`,
          fontheadingWeight: "bold",
          textSizeNormal: "0.9rem",
          button: {
            textTransform: "none",
          },
        },
      }),
    [mode, themeDark, themeLight]
  );
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flex: "1 1 0%",
            flexFlow: "col",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
            height: "98vh",
            margin: "-8px",
          }}
        >
          <BorderLinearProgress
            variant="indeterminate"
            sx={{ width: "192px" }}
            className="w-192 sm:w-320 max-w-full rounded-2"
          />
        </div>
      ) : (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssVarsProvider theme={extendedThemeState}>
              {children}
            </CssVarsProvider>
          </ThemeProvider>
        </ColorModeContext.Provider>
      )}
    </>
  );
}
