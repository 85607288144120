/* eslint import/no-extraneous-dependencies: off */
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const setOrganizations = createAsyncThunk("organizations/setOrganizations", async (inputData, {dispatch, getState}) => {
    let organizations = [];
    if (inputData === null) {
        return null;
    }
    organizations = PopulateOrganizations(inputData.json);
    if (inputData.newSearchText && inputData.newSearchText.length > 0 && organizations && organizations.length > 0) {
        const searchText = inputData.newSearchText.toLowerCase();
        return organizations.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return organizations;
});
function PopulateOrganizations(json) {
    let organization = [];
    const orgsArray = json.operation;
    for (let index in orgsArray) {
        let orgDataObj = {
            "shortName": "",
            "fullName": "",
            "uid": "",
            "image": "",
            "email": "",
            "mobile": "",
            "website": "",
            "status": "",
        };

        orgDataObj.shortName = orgsArray[index].short_name;
        orgDataObj.fullName = orgsArray[index].full_name;
        orgDataObj.uid = orgsArray[index].uid;
        orgDataObj.email = orgsArray[index].email_address;
        orgDataObj.mobile = orgsArray[index].tel_primary;
        orgDataObj.website = orgsArray[index].website_address;
        orgDataObj.status = orgsArray[index].status;
        let orgImageArr = orgsArray[index].image;
        for (let i in orgImageArr) {
            if (orgImageArr[i].image_type === "small_logo" && orgImageArr[i].status === "active") {
                orgDataObj.image = orgImageArr[i].public_url;
            }
        }

        organization.push(orgDataObj);
    }
    return organization;
}

const initialState = null;

const organizationsSlice = createSlice({
    name: "organizations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setOrganizations.fulfilled, (state, action) => action.payload);
    }
});

export const selectOrganization = ({organizations}) => organizations;

export default organizationsSlice.reducer;
