/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Make0IfNegative
} from '../utils/systemInfo';

export const setOpsumData = createAsyncThunk('opsum/setOpsumData', async (inputData, { dispatch, getState }) => {
  let RecordOPSUM = {
    "DataCached": false,
    "MachineType": "",
    "TotalTimespanMinutes": 0,
    "TotalWorkingTime": 0,
    "TotalIdleTime": 0,
    "TotalOfflineTime": 0,
    "TotalUnscheduledTime": 0,
    "TotalSBICrawlMinutes": 0,
    "TotalSBIStopMinutes": 0,
    "TotalCWSCrawlMinutes": 0,
    "TotalCWSBrakeMinutes": 0,
    "TotalSBICrawlCounter": 0,
    "TotalSBIStopCounter": 0,
    "TotalCWSCrawlCounter": 0,
    "TotalCWSBrakeCounter": 0,
    "ActiveDevicesCount": 0,
    "InactiveDevicesCount": 0,
    "TotalRuntime": 0,
    "TotalTimespanMinutesLastWeek": 0,
    "TotalWorkingTimeLastWeek": 0,
    "TotalIdleTimeLastWeek": 0,
    "TotalOfflineTimeLastWeek": 0,
    "TotalUnscheduledTimeLastWeek": 0,
    "TotalSBICrawlMinutesLastWeek": 0,
    "TotalSBIStopMinutesLastWeek": 0,
    "TotalCWSCrawlMinutesLastWeek": 0,
    "TotalCWSBrakeMinutesLastWeek": 0,
    "TotalSBICrawlCounterLastWeek": 0,
    "TotalSBIStopCounterLastWeek": 0,
    "TotalCWSCrawlCounterLastWeek": 0,
    "TotalCWSBrakeCounterLastWeek": 0,
    "ActiveDevicesCountLastWeek": 0,
    "InactiveDevicesCountLastWeek": 0
  };
  if(inputData === null){
    return null
  }
  RecordOPSUM = PopulateDataHomeOPSUM(RecordOPSUM, inputData.jsonCurrent, inputData.jsonPrevious, inputData.machineTypeFilter)
  return RecordOPSUM;
});

function PopulateDataHomeOPSUM(RecordOPSUM, jsonThisWeek, jsonLastWeek, machineTypeFilter = "") {
  if (RecordOPSUM.DataCached === false) {
    const DevicesArray = jsonThisWeek.opsum.device;
    for (let index in DevicesArray) {
      let DeviceType = String(DevicesArray[index].profile.vehicle_type);
      if (DeviceType === "") DeviceType = "Unknown";
      if ((DeviceType !== machineTypeFilter) && (machineTypeFilter !== "")) continue;

      const ProductionJson = DevicesArray[index].production;
      let DeviceTotalTimespanMinutes = ProductionJson.downtime + ProductionJson.idle + ProductionJson.tramming;
      let DeviceTotalWorkingTime = Make0IfNegative(ProductionJson.tramming);
      let DeviceTotalIdleTime = Make0IfNegative(ProductionJson.idle);
      let DeviceTotalUnscheduledTime = Make0IfNegative(DeviceTotalTimespanMinutes - jsonThisWeek.scheduled_time.scheduled);
      let DeviceTotalOfflineTime = Make0IfNegative(ProductionJson.downtime - DeviceTotalUnscheduledTime);
      RecordOPSUM.TotalTimespanMinutes += DeviceTotalTimespanMinutes;
      RecordOPSUM.TotalWorkingTime += DeviceTotalWorkingTime;
      RecordOPSUM.TotalIdleTime += DeviceTotalIdleTime;
      RecordOPSUM.TotalUnscheduledTime += DeviceTotalUnscheduledTime;
      RecordOPSUM.TotalOfflineTime += DeviceTotalOfflineTime;
      RecordOPSUM.TotalRuntime += ProductionJson.engine_run;

      const SafetyJson = DevicesArray[index].safety;
      for (let i in SafetyJson) {
        switch (SafetyJson[i].event_type) {
          case "SBI":
            for (let j in SafetyJson[i].value) {
              switch (SafetyJson[i].value[j].event) {
                case "CRAWL":
                  RecordOPSUM.TotalSBICrawlMinutes += SafetyJson[i].value[j].timer;
                  RecordOPSUM.TotalSBICrawlCounter += SafetyJson[i].value[j].counter;
                  break;
                case "STOP":
                  RecordOPSUM.TotalSBIStopMinutes += SafetyJson[i].value[j].timer;
                  RecordOPSUM.TotalSBIStopCounter += SafetyJson[i].value[j].counter;
                  break;
                default:
                  break;
              }
            }
            break;
          case "CWS":
            for (let j in SafetyJson[i].value) {
              switch (SafetyJson[i].value[j].event) {
                case "CRAWL":
                  RecordOPSUM.TotalCWSCrawlMinutes += SafetyJson[i].value[j].timer;
                  RecordOPSUM.TotalCWSCrawlCounter += SafetyJson[i].value[j].counter;
                  break;
                case "BRAKE":
                  RecordOPSUM.TotalCWSBrakeMinutes += SafetyJson[i].value[j].timer;
                  RecordOPSUM.TotalCWSBrakeCounter += SafetyJson[i].value[j].counter;
                  break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      }
      if (DevicesArray[index].monitoring.active) {
        RecordOPSUM.ActiveDevicesCount++;
      } else {
        RecordOPSUM.InactiveDevicesCount++
      }
    }
    // RecordOPSUM.InactiveDevicesCount = DevicesArray.length - RecordOPSUM.ActiveDevicesCount;



    //////////////////////////////////////////////////////////////


    const DevicesArrayLastWeek = jsonLastWeek.opsum.device;

    for (let index in DevicesArrayLastWeek) {

      let DeviceType = String(DevicesArrayLastWeek[index].profile.vehicle_type);
      if (DeviceType === "") DeviceType = "Unknown";
      if ((DeviceType !== machineTypeFilter) && (machineTypeFilter !== "")) continue;

      const ProductionJson = DevicesArrayLastWeek[index].production;
      let DeviceTotalTimespanMinutes = ProductionJson.downtime + ProductionJson.idle + ProductionJson.tramming;
      let DeviceTotalWorkingTime = Make0IfNegative(ProductionJson.tramming);
      let DeviceTotalIdleTime = Make0IfNegative(ProductionJson.idle);
      let DeviceTotalUnscheduledTime = Make0IfNegative(DeviceTotalTimespanMinutes - jsonThisWeek.scheduled_time.scheduled);
      let DeviceTotalOfflineTime = Make0IfNegative(ProductionJson.downtime - DeviceTotalUnscheduledTime);
      RecordOPSUM.TotalTimespanMinutesLastWeek += DeviceTotalTimespanMinutes;
      RecordOPSUM.TotalWorkingTimeLastWeek += DeviceTotalWorkingTime;
      RecordOPSUM.TotalIdleTimeLastWeek += DeviceTotalIdleTime;
      RecordOPSUM.TotalUnscheduledTimeLastWeek += DeviceTotalUnscheduledTime;
      RecordOPSUM.TotalOfflineTimeLastWeek += DeviceTotalOfflineTime;
      const SafetyJson = DevicesArrayLastWeek[index].safety;
      for (let i in SafetyJson) {
        switch (SafetyJson[i].event_type) {
          case "SBI":
            for (let j in SafetyJson[i].value) {
              switch (SafetyJson[i].value[j].event) {
                case "CRAWL":
                  RecordOPSUM.TotalSBICrawlMinutesLastWeek += SafetyJson[i].value[j].timer;
                  RecordOPSUM.TotalSBICrawlCounterLastWeek += SafetyJson[i].value[j].counter;
                  break;
                case "STOP":
                  RecordOPSUM.TotalSBIStopMinutesLastWeek += SafetyJson[i].value[j].timer;
                  RecordOPSUM.TotalSBIStopCounterLastWeek += SafetyJson[i].value[j].counter;
                  break;
                default:
                  break;
              }
            }
            break;
          case "CWS":
            for (let j in SafetyJson[i].value) {
              switch (SafetyJson[i].value[j].event) {
                case "CRAWL":
                  RecordOPSUM.TotalCWSCrawlMinutesLastWeek += SafetyJson[i].value[j].timer;
                  RecordOPSUM.TotalCWSCrawlCounterLastWeek += SafetyJson[i].value[j].counter;
                  break;
                case "BRAKE":
                  RecordOPSUM.TotalCWSBrakeMinutesLastWeek += SafetyJson[i].value[j].timer;
                  RecordOPSUM.TotalCWSBrakeCounterLastWeek += SafetyJson[i].value[j].counter;
                  break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      }
      if (DevicesArrayLastWeek[index].monitoring.active) {
        RecordOPSUM.ActiveDevicesCountLastWeek++;
      } else {
        RecordOPSUM.InactiveDevicesCountLastWeek++
      }
    }
    // RecordOPSUM.InactiveDevicesCountLastWeek = DevicesArrayLastWeek.length - RecordOPSUM.ActiveDevicesCountLastWeek;
    RecordOPSUM.DataCached = true;
    RecordOPSUM.MachineType = machineTypeFilter;
    //  const localStorageRange = getLocalStorageRange();
    // localStorage.setItem(`simac_home_${localStorageRange.Text}_dst`, new Date());
    //localStorage.setItem(`simac_home_${localStorageRange.Text}_apikey`, api_key_current);
    localStorage.setItem(`simac_home_opsum`, JSON.stringify(RecordOPSUM));
  }
  return RecordOPSUM
};

const initialState = null


const opsumSlice = createSlice({
  name: 'opsum',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setOpsumData.fulfilled, (state, action) => action.payload);
  },
});

export const selectOpsum = ({ opsum }) => opsum;

export default opsumSlice.reducer;
