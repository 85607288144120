/* eslint-disable no-loop-func */
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../common/breadcrumbs";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useNavigate } from "react-router-dom";
import DashboardsService from "../api/dashboards";
import { setDashboards } from "../store/dashboardsSlice";
import {
  GetDateTimeStringToday,
  GetDateTimeString7DaysAgo,
  GetMachineTypeIcon,
} from "../utils/systemInfo";
import DevicesService from "../api/devices";

import { UserPermissionConstants } from "../utils/permissions";
import utils from "../utils/utils";
import Loading from "../common/loading";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Dashboards", href: "dashboards" },
];
const StylePageSpan = styled((props) => <span {...props} />)(({ theme }) => ({
  color: theme.palette.custom.text,
  fontSize: "2.5rem",
}));
const StyleDashboardName = styled((props) => <span {...props} />)(
  ({ theme }) => ({
    // color: theme.palette.custom.text,
    fontSize: "1.5rem",
  })
);
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    backgroundColor: theme.palette.custom.backgroundColor,
    height: "36px",
    minHeight: "36px",
    borderRadius: "3px",
    padding: "8px 16px",
    color: theme.palette.custom.text,
    "&.Mui-selected": {
      color: theme.palette.custom.text,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
      color: theme.palette.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.custom.buttonHoverColor,
    },
  })
);

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  height: "36px",
  backgroundColor: theme.palette.custom.buttonColor,
  color: theme.palette.custom.buttonTextColor,
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
  float: "right",
  width: "min-content",
  whiteSpace: "nowrap",
  padding: "1rem",
}));

function Dashboards() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const organziation = useSelector(({ organziation }) => organziation);
  const permissions = useSelector(({ permissions }) => permissions);
  const [dashboardsList, setDashboardsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [machineDeviceArray, setMachineDeviceArray] = useState(["Fleet"]);
  useEffect(() => {
    dispatch(setDashboards(null));
    const apiKey = localStorage.getItem("apiKey");
    const defaultOrganization = JSON.parse(
      localStorage.getItem("defaultOrganization")
    );
    if (apiKey) {
      //  const apiKey = organziation?.api_key_list?.[0]?.key;
      let NewDashboardItemsDynamic = [];
      DevicesService.getDevices({
        deviceID: "",
        deviceType: "nerohub",
        apiKey,
        historyFromDST: GetDateTimeString7DaysAgo(),
        historyToDST: GetDateTimeStringToday(),
      })
        .then((json) => {
          if (json && json.device_profile && json.device_profile.length > 0) {
            let count = 1;
            let machineTypesArray = [{ key: 0, deviceType: "Fleet" }];
            for (let index = 0; index < json.device_profile.length; index++) {
              let DeviceType;
              if (
                machineTypesArray.findIndex(
                  (x) =>
                    x.deviceType === json.device_profile[index].vehicle_type
                ) === -1
              ) {
                DeviceType = json.device_profile[index].vehicle_type;
                if (DeviceType) {
                  // DeviceType = "Unknown";
                  machineTypesArray.push({
                    key: count,
                    deviceType: DeviceType,
                  });
                  count++;
                }
              }
            }

            machineTypesArray.push({ key: count, deviceType: "Other" });
            setMachineDeviceArray(machineTypesArray);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
        });
      DashboardsService.getGrafanaDashboardOperationLink({
        grafana_dashboard_link_id: "",
        operation_profile_id: defaultOrganization.uid.toString(),
        grafana_dashboard_id: "",
        status: "",
        apiKey,
      })
        .then((json) => {
          if (
            json.success === true &&
            json.grafana_dashboard_operation_link.length > 0
          ) {
            const jsonArray = json.grafana_dashboard_operation_link;
            let LoopCount = 0;
            let AllowedStatuses = ["active"];
            if (
              utils.hasPermission(
                UserPermissionConstants.VIEW_DEVELOPER_PORTAL,
                permissions
              )
            )
              AllowedStatuses.push("hidden");
            for (let i in jsonArray) {
              LoopCount++;
              if (AllowedStatuses.includes(jsonArray[i].status)) {
                DashboardsService.GetGrafanaDashboard({
                  grafana_dashboard_id: jsonArray[i].grafana_dashboard_id,
                  name: "",
                  name_backend: "",
                  name_tab: "",
                  name_type: "",
                  status: "active",
                  apiKey,
                })
                  .then((jsonGrafana) => {
                    const jsonGrafanaDashboard =
                      jsonGrafana.grafana_dashboard[0];
                    if (jsonGrafanaDashboard !== undefined) {
                      NewDashboardItemsDynamic = [
                        ...NewDashboardItemsDynamic,
                        {
                          name: jsonGrafanaDashboard.name,
                          image: `${process.env.PUBLIC_URL}/images/dashboards/${jsonGrafanaDashboard.name_backend}_Dark.png`,
                          page: "/dashboard",
                          href: `?Dashboard=${jsonGrafanaDashboard.name_backend}&DeviceTitleDataName=${jsonGrafanaDashboard.name_type}&timerange`,
                          onclick: "",
                          tab: `${jsonGrafanaDashboard.name_tab}`,
                        },
                      ];
                    }
                    if (LoopCount === jsonArray.length) {
                      NewDashboardItemsDynamic.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      );
                      setDashboardsList(NewDashboardItemsDynamic);
                      dispatch(setDashboards(NewDashboardItemsDynamic));
                      //DashboardItemsDynamic = NewDashboardItemsDynamic;
                    }
                    setLoading(false);
                  })
                  .catch((errorr) => {
                    if (process.env.NODE_ENV === 'development') console.log("errorr", errorr);
                  });
              }
            }
          } else {
            setLoading(false);
          }
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === 'development') console.log("errorr", errorr);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);
  const handleChooseDashboard = (event, dashboardObj) => {
    if (event.ctrlKey || event.button === 1) {
      window.open(
        `${window.location.origin}/${dashboardObj.page}${dashboardObj.href}`,
        "_blank"
      );
    } else if (event.type === "click") {
      navigate(dashboardObj.page + dashboardObj.href);
    }
    // navigate(dashboardObj.page + dashboardObj.href);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function handleAssignDashboardsButton() {
    navigate(`/grafanaBackend`);
  }
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: { xs: "fit-content", sm: "64px" },
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {" "}
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <StylePageSpan>{t("Dashboards")}</StylePageSpan>
          </Box>
          {permissions &&
            utils.hasPermission(
              UserPermissionConstants.VIEW_DEVELOPER_PORTAL,
              permissions
            ) && (
              <>
                <Tooltip title={t("AssignDashboards")}>
                  <StyledButton
                    variant="contained"
                    onClick={handleAssignDashboardsButton}
                  >
                    {t("AssignDashboards")}
                  </StyledButton>
                </Tooltip>
              </>
            )}
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        TabIndicatorProps={{
          style: { backgroundColor: theme.palette.custom.borderColor },
        }}
        value={tabValue}
        onChange={handleTabChange}
        textColor="secondary"
      >
        {machineDeviceArray &&
          machineDeviceArray.length > 1 &&
          machineDeviceArray.map((item) => (
            <StyledTab
              icon={
                item.deviceType !== "Fleet" &&
                item.deviceType !== "Other" && (
                  <div
                    style={{ width: "30px" }}
                    dangerouslySetInnerHTML={{
                      __html: GetMachineTypeIcon(item.deviceType).replace(
                        "theme.palette.custom.text",
                        theme.palette.custom.text
                      ),
                    }}
                  />
                )
              }
              iconPosition="start"
              label={t(item.deviceType)}
              key={item.deviceType}
            />
          ))}
      </Tabs>
      {loading ? (
        <Box
          sx={{
            height: { xs: "100px", sm: "400px" },
            pt: { xs: "50px", sm: "200px" },
          }}
        >
          <Loading />
        </Box>
      ) : dashboardsList && dashboardsList.length > 0 ? (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 4, md: 12 }}
        >
          {dashboardsList.map((dashboard) =>
            (dashboard.tab === "*" &&
              machineDeviceArray.find((x) => x.key === tabValue) &&
              machineDeviceArray.find((x) => x.key === tabValue).deviceType !==
                "Other") ||
            (machineDeviceArray.find((x) => x.key === tabValue) &&
              dashboard.tab ===
                machineDeviceArray.find((x) => x.key === tabValue)
                  .deviceType) ? (
              <Grid
                item
                xs={2}
                sm={4}
                md={4}
                key={dashboard.name}
                sx={{
                  color: theme.palette.custom.text,
                  img: {
                    border: `2px solid ${theme.palette.custom.backgroundColor}`,
                  },
                  "&:hover": {
                    color: theme.palette.custom.borderColor,
                    cursor: "pointer",
                    img: {
                      border: `2px ${theme.palette.custom.borderColor} solid`,
                      margin: 0,
                    },
                  },
                }}
                onClick={(e) => handleChooseDashboard(e, dashboard)}
              >
                <StyleDashboardName>{dashboard.name} </StyleDashboardName>
                <img width="100%" src={dashboard.image} alt="logo" />
              </Grid>
            ) : null
          )}
        </Grid>
      ) : (
        <Typography>
          {t("Contact nerospec for custom dashboard development")}
        </Typography>
      )}
    </Box>
  );
}

export default Dashboards;
