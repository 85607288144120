/* eslint import/no-extraneous-dependencies: off */
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const setMachineStatusData = createAsyncThunk("machineStatus/setMachineStatusData", async (inputData, {dispatch, getState}) => {
    let machineStatus = [];
    if (inputData === null) {
        return null;
    }
    // if (inputData.json&& inputData.json.operation_person_profile_link && inputData.json.operation_person_profile_link.length > 0) {
    // assignUsers = PopulateUsers(inputData.json);
    // }else{
    //     assignUsers = inputData.json
    // }
    machineStatus = inputData.json
    if (inputData.newSearchText && inputData.newSearchText.length > 0 && machineStatus && machineStatus.length > 0) {
        const searchText = inputData.newSearchText.toLowerCase();
        return machineStatus.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return machineStatus;
});

export const setMachineStatusDataDirectly = createAsyncThunk("assignUsers/setAssignUsersDataDirectly", async (inputData, {dispatch, getState}) => {
    
    return inputData;
});

const initialState = null;

const machineStatusSlice = createSlice({
    name: "machineStatus",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setMachineStatusData.fulfilled, (state, action) => action.payload);
        builder.addCase(setMachineStatusDataDirectly.fulfilled, (state, action) => action.payload);
    }
});

export const selectMachineStatus = ({machineStatus}) => machineStatus;

export default machineStatusSlice.reducer;
