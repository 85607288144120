/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setDashboardsBackend = createAsyncThunk(
  "dashboardsBackend/setDashboardsBackend",
  async (inputData, { dispatch, getState }) => {
    const data = inputData.data;
    if (inputData.newSearchText?.length && inputData.data?.length) {
        const searchText = inputData.newSearchText.toLowerCase();
        return data.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return data;
  }
);

const initialState = null;

const dashboardsBackendSlice = createSlice({
  name: "dashboardsBackend",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDashboardsBackend.fulfilled, (state, action) => action.payload);
  },
});

export const selectDashboardsBackend = ({ dashboardsBackend }) =>
  dashboardsBackend;

export default dashboardsBackendSlice.reducer;
