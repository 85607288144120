import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  GetMachineTypeIcon,
  getUrlVars,
  GetGrafanaDashboardUID,
  GetDateTimeStringToday,
} from "../utils/systemInfo";
import DevicesService from "../api/devices";

const NumberTypography = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.borderColor,
    m: 0,
  })
);

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.custom.light,
  },
}));
function DevicesMenu({
  devices,
  isSmall,
  pageType,
  compareDevice2,
  handleChangeDevice,
}) {
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const device1 = useSelector(({ device }) => device);
  const device2 = useSelector(({ deviceCompare }) => deviceCompare);
  const [device, setDevice] = useState();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [devicesList, setDevicesList] = useState(null);
  const [searchText, setSearchText] = useState("");
  const organziation = useSelector(({ organziation }) => organziation);

  const DeviceTitleDataName = String(getUrlVars()["DeviceTitleDataName"]);
  useEffect(() => {
    setDevice(compareDevice2 ? device2 : device1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device1, device2]);
  useEffect(() => {
    setDevicesList(undefined);
    if (organziation) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      let DevicesClass;
      switch (DeviceTitleDataName.toLowerCase()) {
        case "machine":
        default:
          DevicesClass = { deviceType: ["nerohub", "nanohub"], source: "" };
          DevicesService.getDevices({
            DeviceID: "",
            DeviceType: DevicesClass.deviceType,
            Source: DevicesClass.source,
            apiKey,
            HistoryFromDST: "",
            HistoryToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                setDevicesList(json.device_profile);
              }
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
            });
          break;

        case "device":
          DevicesClass = { deviceType: [], source: "iot" };
          DevicesService.getDevices({
            DeviceID: "",
            DeviceType: DevicesClass.deviceType,
            Source: DevicesClass.source,
            Live: false,
            History: false,
            apiKey,
            HistoryFromDST: "",
            HistoryToDST: GetDateTimeStringToday(),
          })
            .then((json) => {
              if (
                json &&
                json.device_profile &&
                json.device_profile.length > 0
              ) {
                setDevicesList(json.device_profile);
              }
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
            });
          break;
      }
    }
  }, [organziation, DeviceTitleDataName]);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleChooseOrganization = (deviceObj) => {
    const DeviceTitleDataName = String(getUrlVars()["DeviceTitleDataName"]);
    let MachineType = String(deviceObj.machine_type);
    const GrafanaDashboardUID = GetGrafanaDashboardUID(MachineType);
    let href;
    switch (pageType) {
      case "history":
        href = `/deviceHistory?Dashboard=${GrafanaDashboardUID}&MachineName=${deviceObj.name}&device_uid=${deviceObj.device_uid}&DeviceTitleDataName=${DeviceTitleDataName}&timerange`;
        navigate(href);
        handleChangeDevice();
        break;
      case "signals":
        const varSensorID = getUrlVars()["SensorID"];
        href = `/deviceSignals?Dashboard=MachineMultipleSignal&MachineName=${device.DeviceName}&device_uid=${device.DeviceID}&SensorID=${varSensorID}`;
        // const newUrl = window.location
        navigate(href);
        break;
      case "compareDevices":
        if (compareDevice2) {
          navigate(
            `/deviceCompare?device=${String(
              getUrlVars()[compareDevice2 ? "device" : "device2"]
            )}&device2=${
              deviceObj.device_uid
            }&DeviceTitleDataName=${DeviceTitleDataName}`
          );
        } else {
          navigate(
            `/deviceCompare?device=${deviceObj.device_uid}&device2=${String(
              getUrlVars()[compareDevice2 ? "device" : "device2"]
            )}&DeviceTitleDataName=${DeviceTitleDataName}`
          );
        }
        break;

      default:
        navigate(`/device?device=${deviceObj.device_uid}`);
        break;
    }

    setAnchorElUser(null);
  };
  function handleSearch(ev) {
    const newSearchText = ev.target.value;

    setSearchText(newSearchText);

    if (newSearchText.length !== 0 && devices) {
      setDevicesList(
        devices.filter((item) =>
          item.name.toLowerCase().includes(newSearchText.toLowerCase())
        )
      );
      return;
    }
  }
  return (
    <>
      <Tooltip title="Open settings">
        <Button
          variant="text"
          onClick={handleOpenUserMenu}
          //  startIcon={<Avatar alt="Remy Sharp" src={handleGetIcon(organziation)} />}
          endIcon={
            Boolean(anchorElUser) ? (
              <KeyboardArrowUpIcon
                sx={{
                  color: theme.palette.custom.borderColor,
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                sx={{
                  color: theme.palette.custom.borderColor,
                  "&:hover": {
                    backgroundColor: theme.palette.custom.buttonHoverColor,
                    color: theme.palette.custom.buttonHoverTextColor,
                    boxShadow: "none",
                  },
                }}
              />
            )
          }
        >
          <NumberTypography variant={isSmall ? "h4" : "h3"}>
            {device ? device.DeviceName.toUpperCase() : ""}
          </NumberTypography>
        </Button>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        TransitionProps={{
          onEntered: () => {
            searchInputRef.current.focus();
          },
        }}
      >
        <TextField
          inputRef={searchInputRef}
          value={searchText}
          onChange={handleSearch}
          margin="normal"
          fullWidth
          id="search"
          label={t("Search")}
          name="search"
          autoFocus
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          sx={{
            ml: "2%",
            width: "200px",
            borderRadius: "11px",
            backgroundColor: theme.palette.custom.light,
          }}
        />
        <Divider
          sx={{ my: "10px", backgroundColor: theme.palette.custom.light }}
        />
        {devicesList &&
          devicesList.map((setting) => (
            <MenuItem
              key={setting?.name}
              onClick={() => handleChooseOrganization(setting)}
            >
              {/* <Avatar sx={{ mr: 1 }} alt="Remy Sharp" src={} /> */}
              <div
                style={{ width: "40px", marginRight: "4px" }}
                dangerouslySetInnerHTML={{
                  __html: GetMachineTypeIcon(setting.vehicle_type).replace(
                    "theme.palette.custom.text",
                    theme.palette.custom.text
                  ),
                }}
              />
              <Typography textAlign="center">
                {setting.name ? setting.name : ""}
              </Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  );
}

export default DevicesMenu;
