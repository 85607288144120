/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetGrafanaDashboardUID,
  GetMachineTypeIcon,
} from "../utils/systemInfo";

export const setMaintenanceData = createAsyncThunk('maintenance/setMaintenanceData', async (inputData, { dispatch, getState }) => {
  let RecordOPSUM = [];
  if (inputData === null) {
    return null
  }
  RecordOPSUM = PopulateDataHomeOPSUM(inputData.jsonCurrent,
    inputData.maintenanceSchedules,
    inputData.machineTypeFilter,
    inputData.historyFromDST,
    inputData.historyToDST,
  )
  if (inputData.newSearchText && inputData.newSearchText.length > 0 && RecordOPSUM && RecordOPSUM.length > 0) {
    const searchText = inputData.newSearchText.toLowerCase();
        return RecordOPSUM.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );

  }
  return RecordOPSUM;
});
function PopulateDataHomeOPSUM(jsonThisWeek, maintenanceSchedules, machineTypeFilter = "", historyFromDST, historyToDST) {
  let OpsumArray = [];
  const DevicesArray = jsonThisWeek.opsum.device;
  for (let index in DevicesArray) {
    let opsumDataObj = {
      MachineType: "",
      DeviceName: "",
      DeviceID: "",
      DeviceStatus: "",
      DeviceLatestOPSUM: "",
      DeviceLatestData: "",
      DeviceLatestRaw: "",
      DeviceLastSeen: "",
      NamedLocationOriginal: "",
      GrafanaDashboardUID: "",
      DeviceType: "",
      DeviceTypeIcon: "",
      MaintenanceStatus: "",
      MaintenanceStatusDescription: "",
      DeviceTitleDataName: "Machine",
    };
    opsumDataObj.DeviceName = DevicesArray[index].profile.name;
    opsumDataObj.DeviceID = DevicesArray[index].profile.device_uid;
    const profileId = DevicesArray[index].profile.device_profile_id;
    const DeviceLatestData = DevicesArray[index].profile.latest_data_dst;
    opsumDataObj.DeviceLatestOPSUM = DevicesArray[index].profile.dst_last_opsum;
    opsumDataObj.DeviceLatestRaw = DevicesArray[index].profile.dst_last_raw;
    opsumDataObj.DeviceLastSeen = DevicesArray[index].profile.dst_last_seen;
    if (
      new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
      opsumDataObj.DeviceLastSeen.length === 0
    ) {
      opsumDataObj.DeviceLastSeen = DeviceLatestData;
      if (
        new Date(opsumDataObj.DeviceLastSeen).getFullYear() < 1900 ||
        opsumDataObj.DeviceLastSeen.length === 0
      )
        opsumDataObj.DeviceLastSeen = " not seen";
    }

    // DeviceType = String(DevicesArray[index].profile.vehicle_type);
    // if (DeviceType == "") DeviceType = "Unknown";

    let MachineType = String(DevicesArray[index].profile.machine_type);
    opsumDataObj.GrafanaDashboardUID = GetGrafanaDashboardUID(MachineType);

    let DeviceType = String(DevicesArray[index].profile.vehicle_type);
    if (DeviceType === "") opsumDataObj.DeviceType = "Unknown";
    const DeviceTypeIcon = GetMachineTypeIcon(DeviceType);
    opsumDataObj.DeviceTypeIcon = DeviceTypeIcon;
    if (DeviceType !== machineTypeFilter && machineTypeFilter !== "") continue;

    let LastLocation = DevicesArray[index]?.location?.named?.lookup?.name;
    if (LastLocation === undefined || LastLocation === "") {
      LastLocation = `Unknown`;
    }
    opsumDataObj.NamedLocationOriginal = LastLocation;
    const deviceMaintenanceObj = maintenanceSchedules.find(
      (x) => x.device_profile_id === profileId
    );

    if (deviceMaintenanceObj) {
      if (new Date(deviceMaintenanceObj.date_to) >= new Date(historyFromDST)) {
        opsumDataObj.MaintenanceStatus =
          deviceMaintenanceObj.maintenance_type_id;
        opsumDataObj.MaintenanceStatusDescription = `${deviceMaintenanceObj.name} (${deviceMaintenanceObj.description})`;
      }
    }

    OpsumArray.push(opsumDataObj);
  }
  return OpsumArray;
}

const initialState = null;

const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setMaintenanceData.fulfilled, (state, action) => action.payload);
  },
});

export const selectMaintenance = ({ maintenance }) => maintenance;

export default maintenanceSlice.reducer;
