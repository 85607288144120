
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Hexagons from "./hexagon";

export default function HomeColumn({ icon, title, content, redirectLink, onMouseEnter, onMouseLeave }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(redirectLink)
    };
    return (
        <Box sx={{
            width: '100%',
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap", textAlign: "center", height: '100%'
        }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <Hexagons onClick={handleClick} style={{ width: '150px', height: '250px' }}>

                {icon}
            </Hexagons>
            <Typography sx={{ fontSize: '1.75rem', mt: '74px', color: theme.palette.custom.borderColor }} variant="h3" gutterBottom>
                {title?.toUpperCase()}
            </Typography>
            <Box sx={{ height: 'calc(100% - 300px)', p: '12px', textAlign: "center", borderRadius: '16px', border: `1px solid ${theme.palette.custom.borderColor}` }}>
                {content}

            </Box>
        </Box>
    );
}