import axios from "axios";
import { config } from "../config";
import Utils from "../utils/utils";

class OrganizationService extends Utils.EventEmitter {
  GetUserPassword(orgInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          uid: orgInfo.operation_profile_id,
          full_name: orgInfo.operation_full_name,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: orgInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/profile/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetOperationProfile(orgInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            uid: orgInfo.uid,
            full_name: orgInfo.full_name,
            short_name: orgInfo.short_name,
            registration_number: orgInfo.registration_number,
            vat_number: orgInfo.vat_number,
            vat_percentage: orgInfo.vat_percentage,
            tax_number: orgInfo.tax_number,
            tel_primary: orgInfo.tel_primary,
            tel_secondary: orgInfo.tel_secondary,
            email_address: orgInfo.email_address,
            email_additional: orgInfo.email_additional,
            website_address: orgInfo.website_address,
            admin_domain: orgInfo.admin_domain,
            commercial_domain: orgInfo.commercial_domain,
            email_domain: orgInfo.email_domain,
            super_company: orgInfo.super_company,
            feature_lock: orgInfo.feature_lock,
            status: orgInfo.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: orgInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/profile/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetOperationProfile(orgInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          uid: orgInfo.operation_profile_id,
          full_name: orgInfo.operation_full_name,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: orgInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/profile/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetOperationProfileAttributes({ uid, apiKey }) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_profile_id: uid,
          channel: "",
          status: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/profile/attribute/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetOperationProfileAttributes({ attributes, uid, apiKey }) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_profile_id: uid,
          channel: "",
          status: "",
        },
        post: attributes.map((a) => ({ ...a, operation_profile_id: uid })),
        // [
        //     {
        //         operation_profile_id: uid,
        //         channel: "",
        //         status: "",
        //         value: "+2",
        //     }
        // ]
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/profile/attribute/post`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetOperationImages(type, form_id) {
    let form = document.getElementById(form_id);
    let data = new FormData(form);
    // If you want to add an extra field for the FormData
    // data.append("CustomField", "This is some extra data, testing");
    data.append("status", "active");
    data.append("image_type", type);
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: data,
      });
      const headers = {
        "Content-Type": "application/json",
      };
      if (data.has("file")) {
        fetch(`${config.url.api_ip}/ns-core/operation/profile/image/post`, {
          method: "post",
          headers,
          body,
        })
          .then((response) => response.json())
          .then((json) => {
            resolve(json);
          })
          .catch((err) => {
            if (process.env.NODE_ENV === "development") console.log("err ", err);
            reject(
              err.response !== undefined
                ? err.response.data.error_description
                : null
            );
          });
      }
    });
  }

  GetApiKeys(api_key_id = "", operation_uid = "", status = "active", apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          api_key_id: api_key_id,
          operation_uid: operation_uid,
          key: "",
          expiry_date: "",
          super_key: "",
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/app/api-key/get`, {
        method: "post",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetOperationPersonLink(orgInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_profile_uid: orgInfo.orgID,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: orgInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/person/link/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetOperationPersonLink(user, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            operation_id: user.operation_id,
            person_id: user.person_id,
            user_type_uid: user.user_type_uid,
            auto_assign_ticket: "0",
            status: user.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      axios
        .post(
          `${config.url.api_ip}/ns-core/operation/person/link`,
          body,
          headers
        )

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetAssignDeviceToOrg(orgInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_profile_uid: orgInfo.operation_uid,
          device_profile_id: orgInfo.device_id,
          device_profile_link_id: "",
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: orgInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/device/profile/link/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetPersonDeviceLink(getLinkInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_uid: getLinkInfo.operation_uid,
          person_id: getLinkInfo.person_id,
          device_id: getLinkInfo.device_id,
          permission_view: '["owner","viewer","editor","developer"]',
          status: getLinkInfo.status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: getLinkInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/person/device/link/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetAssignDeviceToOrg(linkInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            operation_uid: linkInfo.operation_uid,
            device_id: linkInfo.device_id,
            status: linkInfo.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: linkInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      axios
        .post(
          `${config.url.api_ip}/ns-core/operation/device/link/post`,
          body,
          headers
        )

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  SetPersonDeviceLink(linkInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            operation_uid: parseInt(linkInfo.operation_uid),
            person_id: linkInfo.person_id,
            device_id: linkInfo.device_id,
            status: linkInfo.status,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: linkInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      axios
        .post(
          `${config.url.api_ip}/ns-core/operation/person/device/link/post`,
          body,
          headers
        )

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  SetPersonDevicePermission(appData) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        post: [
          {
            operation_uid: appData.operation_uid,
            person_id: appData.person_id,
            device_id: appData.device_id,
            permission: appData.permission,
          },
        ],
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: localStorage.getItem("apiKey"),
        apiSession: localStorage.getItem("auth_token"),
      };
      axios
        .post(
          `${config.url.api_ip}/ns-core/operation/person/device/permission/post`,
          body,
          headers
        )

        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);
          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetOperationNotifications(orgInfo) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_uid: orgInfo.orgUid,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: orgInfo.apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/notifications/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }

  GetOperationDepartment(
    department_id,
    operation_profile_uid,
    name,
    status,
    apiKey
  ) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          department_id: department_id,
          operation_profile_uid: operation_profile_uid,
          name: name,
          status: status,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/department/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
  GetOperationFeatures(operation_profile_uid, apiKey) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        get: {
          operation_profile_uid,
        },
      });
      const headers = {
        "Content-Type": "application/json",
        apiKey: apiKey,
        apiSession: localStorage.getItem("auth_token"),
      };
      fetch(`${config.url.api_ip}/ns-core/operation/feature/link/get`, {
        method: "POST",
        headers,
        body,
      })
        .then((response) => response.json())
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          if (process.env.NODE_ENV === "development") console.log("err ", err);

          reject(
            err.response !== undefined
              ? err.response.data.error_description
              : null
          );
        });
    });
  }
}
const instance = new OrganizationService();

export default instance;
