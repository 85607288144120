import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUrlVars } from "../utils/systemInfo";
import SignalsService from "../api/signals";
import Loading from "../common/loading";
import StyledTypography from "../common/StyledTypography";
import CustomTextField from "../common/CustomTextField";

const StyledLoadingButton = styled((props) => <LoadingButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.custom.borderColor,
    width: "10%",
    "&.MuiLoadingButton-loading": {
      backgroundColor: theme.palette.custom.light,
    },
  })
);

function DeviceSignalEditPage() {
  const theme = useTheme();
  const { t } = useTranslation();
  const [name, setName] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [eunit, setUnit] = useState(undefined);
  const [color, setColor] = useState(undefined);
  const [machineType, setMachineType] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [sortIndex, setSortIndex] = useState(undefined);
  const [signalIndex, setSignalIndex] = useState(undefined);
  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);
  const [lowThreshold, setLowThreshold] = useState(undefined);
  const [highThreshold, setHighThreshold] = useState(undefined);
  const [tag, setTag] = useState(undefined);
  const [savingLoading, setSavingLoading] = useState(false);
  const [loadingValue, setLoadingValue] = useState(true);
  const signalDescriptorId = String(getUrlVars()["signalDescriptorId"]);
  const device = useSelector(({ device }) => device);
  const navigate = useNavigate();

  useEffect(() => {
    SignalsService.GetDeviceSignalDescriptors({
      signal_descriptor_id: signalDescriptorId,
      apiKey: localStorage.getItem("apiKey"),
    })
      .then((json) => {
        const signal = json.device_signal_descriptor[0];
        setName(signal.name);
        setDescription(signal.description);
        setUnit(signal.eunit);
        setColor(signal.color);
        setMachineType(signal.machine_type);
        setStatus(signal.status);
        setSortIndex(signal.sort_idx);
        setSignalIndex(signal.signal_idx);
        setMin(signal.min);
        setMax(signal.max);
        setLowThreshold(signal.low_thresh);
        setHighThreshold(signal.high_thresh);
        setTag(signal.tag);
        setLoadingValue(false);
      })
      .catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalDescriptorId]);
  const handleSubmit = (event) => {
    setSavingLoading(true);
    if (signalDescriptorId) {
      SignalsService.SetDeviceSignalDescriptors({
        signal_descriptor_id: signalDescriptorId,
        machine_type: machineType,
        status: status,
        sort_idx: sortIndex,
        signal_idx: signalIndex,
        name: name,
        eunit: eunit,
        color: color,
        min: min,
        max: max,
        low_thresh: lowThreshold,
        high_thresh: highThreshold,
        updated_timestamp_from: "",
        apiKey: localStorage.getItem("apiKey"),
      })
        .then((json) => {
          setSavingLoading(false);
        })
        .catch((error) => {});
    }
  };
  const handleReturn = (event) => {
    navigate(
      `/device?device=${device?.DeviceID}&DeviceTitleDataName=${device?.DeviceTitleDataName}`
    );
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <IconButton
          size="small"
          sx={{
            backgroundColor: theme.palette.custom.buttonColor,
            color: theme.palette.custom.buttonTextColor,
            mr: 4,
            width: "40px",
            height: "40px",
          }}
          onClick={handleReturn}
        >
          <ArrowBackOutlinedIcon fontSize="inherit" />
        </IconButton>
        <StyledTypography
          sx={{ color: theme.palette.custom.text }}
          variant="h4"
        >
          {`${t("EditDeviceSignal")}`}
        </StyledTypography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      {!loadingValue ? (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Name")}
              name="name"
              id="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              autoComplete="name"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
                mr: { xs: "0%", sm: "2%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Description")}
              name="description"
              id="description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              autoComplete="description"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
          </div>
          <div>
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("EUnit")}
              name="eunit"
              id="eunit"
              value={eunit}
              onChange={(e) => {
                setUnit(e.target.value);
              }}
              autoComplete="eunit"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
                mr: { xs: "0%", sm: "2%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Color")}
              name="color"
              type="number"
              id="color"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
              }}
              autoComplete="color"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
          </div>
          <div>
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("MachineType")}
              name="machineType"
              id="machineType"
              value={machineType}
              onChange={(e) => {
                setMachineType(e.target.value);
              }}
              autoComplete="machineType"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Status")}
              name="status"
              id="status"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              autoComplete="status"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
          </div>
          <div>
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Sort Index")}
              name="sortIndex"
              id="sortIndex"
              type="number"
              value={sortIndex}
              onChange={(e) => {
                setSortIndex(e.target.value);
              }}
              autoComplete="sortIndex"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Signal Index")}
              name="singalIndex"
              id="singalIndex"
              type="number"
              value={signalIndex}
              onChange={(e) => {
                setSignalIndex(e.target.value);
              }}
              autoComplete="singalIndex"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
          </div>
          <div>
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Min")}
              name="min"
              id="min"
              type="number"
              value={min}
              onChange={(e) => {
                setMin(e.target.value);
              }}
              autoComplete="min"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Max")}
              name="max"
              type="number"
              id="max"
              value={max}
              onChange={(e) => {
                setMax(e.target.value);
              }}
              autoComplete="max"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
          </div>
          <div>
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Low Threshold")}
              name="lowThreshold"
              id="lowThreshold"
              type="number"
              value={lowThreshold}
              onChange={(e) => {
                setLowThreshold(e.target.value);
              }}
              autoComplete="lowThreshold"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("High Threshold")}
              name="highThreshold"
              id="highThreshold"
              type="number"
              value={highThreshold}
              onChange={(e) => {
                setHighThreshold(e.target.value);
              }}
              autoComplete="highThreshold"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
          </div>
          <div>
            <CustomTextField
              margin="normal"
              fullWidth
              label={t("Tag")}
              name="tag"
              id="tag"
              value={tag}
              onChange={(e) => {
                setTag(e.target.value);
              }}
              autoComplete="tag"
              autoFocus
              variant="filled"
              sx={{
                width: { xs: "100%", sm: "47%" },
              }}
              InputLabelProps={{
                style: { color: theme.palette.custom.text },
              }}
            />
          </div>
        </Box>
      ) : (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      )}
      <Box sx={{ width: "100%", alignItems: "end", textAlign: "end" }}>
        <StyledLoadingButton
          loading={savingLoading}
          loadingIndicator={<CircularProgress color="primary" size={16} />}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          onClick={handleSubmit}
        >
          {t("Save")}
        </StyledLoadingButton>
      </Box>
    </Box>
  );
}

export default DeviceSignalEditPage;
