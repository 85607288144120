import { createBrowserRouter, useLocation, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import LoginPage from "../pages/loginPage";
import IndexPage from "../pages/indexPage";
import Safety from "../pages/safety";
import Monitoring from "../pages/monitoring";
import Maintenance from "../pages/maintenance";
import Productivity from "../pages/productivity";
import { setUser } from "../store/userSlice";
import { setOrganization } from "../store/organziationSlice";
import { setPermissions } from "../store/permissionsSlice";
import About from "../pages/about";
import Profile from "../pages/profile";
import Organization from "../pages/organization";
import Organizations from "../pages/organizationsPage";
import Layout from "../common/Layout/layout";
import Machines from "../pages/machines";
import Machine from "../pages/machine";
import MachineCompare from "../pages/machineCompare";
import Users from "../pages/users";
import CreateUser from "../pages/userCreate";
import AssignUsers from "../pages/assignUsers";
import AssignDevices from "../pages/assignDevices";
import DevToUsers from "../pages/devToUsers";
import AddEditMachine from "../pages/addEditMachine";
import AddEditDashboard from "../pages/addEditDashboard";
import Dashboard from "../pages/dashboard"; // import personService from '../api/person';
import Dashboards from "../pages/dashboards";
import DeviceHistory from "../pages/deviceHistory";
import DeviceSignals from "../pages/deviceSignals";
import Documentation from "../pages/documentation";
import Changelog from "../pages/changelog";
import HelpdeskDashboard from "../pages/indexHelpdesk";
import ContactUs from "../pages/contactUs";
import Tickets from "../pages/tickets";
import MyTickets from "../pages/myTickets";
import Templates from "../pages/templates";
import TicketTemplateRequirements from "../pages/ticketTemplateRequirements";
import NewTicket from "../pages/newTicket";
import GrafanaBackend from "../pages/grafanaBackend";
import NewTicketTemplate from "../pages/newTicketTemplate";
import NotificationsPage from "../pages/notificationsPage";
import usePrevious from "../common/cutomHooks/usePrevious";
import AddOrganizationLink from "../pages/addOrganizationLink";
import AddMachineStatusPage from "../pages/addMachineStatusPage";
import AddMachineDocsPage from "../pages/addMachineDocsPage";
import MachineStatus from "../pages/machineStatus";
import DeviceSignalEditPage from "../pages/deviceSignalEditPage";
import MachineStatusCommentsPage from "../pages/machineStatusCommentPage";
import MyProfile from "../pages/myProfile";
import AddShift from "../pages/addShift";

function RequireAuth({ children }) {
  const token = localStorage.getItem("auth_token");
  const user = localStorage.getItem("user");
  const prevUser = usePrevious(user);
  const defaultOrganization = localStorage.getItem("defaultOrganization");
  let location = useLocation();
  const dispatch = useDispatch();

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (user && user !== prevUser) {
    const userObj = JSON.parse(user);
    dispatch(setUser(userObj));
    if (defaultOrganization) {
      const orgObj = JSON.parse(defaultOrganization);
      dispatch(setOrganization(orgObj));
    }
    const permissions = localStorage.getItem("permissions");
    if (permissions) {
      const permissionsObj = JSON.parse(permissions);
      dispatch(setPermissions(permissionsObj));
    }
  }

  return children;
}
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "",
        element: (
          <RequireAuth>
            <IndexPage />
          </RequireAuth>
        ),
      },
      {
        path: "safety",
        element: (
          <RequireAuth>
            <Safety />
          </RequireAuth>
        ),
      },
      {
        path: "monitoring",
        element: (
          <RequireAuth>
            <Monitoring />
          </RequireAuth>
        ),
      },
      {
        path: "maintenance",
        element: (
          <RequireAuth>
            <Maintenance />
          </RequireAuth>
        ),
      },
      {
        path: "productivity",
        element: (
          <RequireAuth>
            <Productivity />
          </RequireAuth>
        ),
      },
      {
        path: "devices",
        element: (
          <RequireAuth>
            <Machines />
          </RequireAuth>
        ),
      },
      {
        path: "myDevices",
        element: (
          <RequireAuth>
            <Machines />
          </RequireAuth>
        ),
      },
      {
        path: "machines",
        element: (
          <RequireAuth>
            <Machines />
          </RequireAuth>
        ),
      },
      {
        path: "myMachines",
        element: (
          <RequireAuth>
            <Machines />
          </RequireAuth>
        ),
      },
      {
        path: "device",
        element: (
          <RequireAuth>
            <Machine />
          </RequireAuth>
        ),
      },
      {
        path: "deviceCompare",
        element: (
          <RequireAuth>
            <MachineCompare />
          </RequireAuth>
        ),
      },
      {
        path: "about",
        element: (
          <RequireAuth>
            <About />
          </RequireAuth>
        ),
      },
      {
        path: "profile",
        element: (
          <RequireAuth>
            <Profile />
          </RequireAuth>
        ),
      },
      {
        path: "organization",
        element: (
          <RequireAuth>
            <Organization />
          </RequireAuth>
        ),
      },
      {
        path: "organizations",
        element: (
          <RequireAuth>
            <Organizations />
          </RequireAuth>
        ),
      },
      {
        path: "organizationCreate",
        element: (
          <RequireAuth>
            <Organization new />
          </RequireAuth>
        ),
      },
      {
        path: "deviceProfile",
        element: (
          <RequireAuth>
            <AddEditMachine />
          </RequireAuth>
        ),
      },
      {
        path: "dashboard",
        element: (
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        ),
      },
      {
        path: "grafanaBackendDashboardAddEdit",
        element: (
          <RequireAuth>
            <AddEditDashboard />
          </RequireAuth>
        ),
      },
      {
        path: "addEditOrganizationLink",
        element: (
          <RequireAuth>
            <AddOrganizationLink />
          </RequireAuth>
        ),
      },
      {
        path: "users",
        element: (
          <RequireAuth>
            <Users />
          </RequireAuth>
        ),
      },
      {
        path: "userCreate",
        element: (
          <RequireAuth>
            <CreateUser />
          </RequireAuth>
        ),
      },
      {
        path: "assignUsers",
        element: (
          <RequireAuth>
            <AssignUsers />
          </RequireAuth>
        ),
      },
      {
        path: "assignDevices",
        element: (
          <RequireAuth>
            <AssignDevices />
          </RequireAuth>
        ),
      },
      {
        path: "devToUsers",
        element: (
          <RequireAuth>
            <DevToUsers />
          </RequireAuth>
        ),
      },
      {
        path: "dashboards",
        element: (
          <RequireAuth>
            <Dashboards />
          </RequireAuth>
        ),
      },
      {
        path: "deviceHistory",
        element: (
          <RequireAuth>
            <DeviceHistory />
          </RequireAuth>
        ),
      },
      {
        path: "deviceSignals",
        element: (
          <RequireAuth>
            <DeviceSignals />
          </RequireAuth>
        ),
      },
      {
        path: "documentation",
        element: (
          <RequireAuth>
            <Documentation />
          </RequireAuth>
        ),
      },
      {
        path: "contactus",
        element: (
          <RequireAuth>
            <ContactUs />
          </RequireAuth>
        ),
      },
      {
        path: "changelog",
        element: (
          <RequireAuth>
            <Changelog />
          </RequireAuth>
        ),
      },
      {
        path: "indexHelpdesk",
        element: (
          <RequireAuth>
            <HelpdeskDashboard />
          </RequireAuth>
        ),
      },
      {
        path: "tickets",
        element: (
          <RequireAuth>
            <Tickets />
          </RequireAuth>
        ),
      },
      {
        path: "myTickets",
        element: (
          <RequireAuth>
            <MyTickets />
          </RequireAuth>
        ),
      },
      {
        path: "templates",
        element: (
          <RequireAuth>
            <Templates />
          </RequireAuth>
        ),
      },
      {
        path: "ticketTemplateRequirements",
        element: (
          <RequireAuth>
            <TicketTemplateRequirements />
          </RequireAuth>
        ),
      },
      {
        path: "newTicketTemplate",
        element: (
          <RequireAuth>
            <NewTicketTemplate />
          </RequireAuth>
        ),
      },
      {
        path: "newTicket",
        element: (
          <RequireAuth>
            <NewTicket />
          </RequireAuth>
        ),
      },
      {
        path: "grafanaBackend",
        element: (
          <RequireAuth>
            <GrafanaBackend />
          </RequireAuth>
        ),
      },
      {
        path: "notifications",
        element: (
          <RequireAuth>
            <NotificationsPage />
          </RequireAuth>
        ),
      },
      {
        path: "addMachineStatus",
        element: (
          <RequireAuth>
            <AddMachineStatusPage />
          </RequireAuth>
        ),
      },
      {
        path: "addMachineDocs",
        element: (
          <RequireAuth>
            <AddMachineDocsPage />
          </RequireAuth>
        ),
      },
      {
        path: "machineStatus",
        element: (
          <RequireAuth>
            <MachineStatus />
          </RequireAuth>
        ),
      },
      {
        path: "editDeviceSignal",
        element: (
          <RequireAuth>
            <DeviceSignalEditPage />
          </RequireAuth>
        ),
      },
      {
        path: "machineComments",
        element: (
          <RequireAuth>
            <MachineStatusCommentsPage />
          </RequireAuth>
        ),
      },
      {
        path: "myProfile",
        element: (
          <RequireAuth>
            <MyProfile />
          </RequireAuth>
        ),
      },
      {
        path: "addShift",
        element: (
          <RequireAuth>
            <AddShift />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "login",
    element: <LoginPage />,
  },
]);
export default router;
