import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function NavListItem({ navItem, menuOpen, onRerender }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = (event, navItemObj) => {
    if (navItem.dropdown) {
      setOpen(!open);
    } else {
      if (
        navItemObj.url.split("/").pop() ===
        window.location.href.split("/").pop()
      ) {
        onRerender();
      }
      if (event.ctrlKey || event.button === 1) {
        window.open(`${window.location.origin}/${navItemObj.url}`, "_blank");
      } else if (event.type === "click") {
        navigate(navItemObj.url);
      }
      //      navigate(navItemObj.url);
    }
  };
  const handleDropClick = (event, dropItemObj) => {
    if (event.ctrlKey || event.button === 1) {
      window.open(`${window.location.origin}/${dropItemObj.url}`, "_blank");
    } else if (event.type === "click") {
      navigate(dropItemObj.url);
    }
    //navigate(dropItemObj.url);
  };

  return (
    <>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: menuOpen ? "initial" : "center",
          px: 2.5,
          color: theme.palette.custom.text,
        }}
        onClick={(e) => handleClick(e, navItem)}
        key={navItem.id}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: menuOpen ? 3 : "auto",
            justifyContent: "center",
            color: theme.palette.custom.text,
          }}
        >
          {navItem.icon}
        </ListItemIcon>
        <ListItemText
          primary={t(navItem.label)}
          sx={{ color: theme.palette.custom.text, opacity: menuOpen ? 1 : 0 }}
        />
        {navItem.dropdown && navItem.dropdown.length > 0 ? (
          open ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : null}
      </ListItemButton>
      {navItem.dropdown && navItem.dropdown.length > 0 ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {navItem.dropdown.map((dropdownItem, dropdownindex) => (
              <ListItemButton
                sx={{ pl: 4 }}
                key={dropdownindex}
                onClick={(e) => handleDropClick(e, dropdownItem)}
              >
                <ListItemIcon sx={{ color: theme.palette.custom.text }}>
                  {dropdownItem.icon}
                </ListItemIcon>
                <ListItemText primary={t(dropdownItem.label)} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  );
}
