import { Fragment } from "react";
import { Box, TableRow, TableCell } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StyledTypography from "../common/StyledTypography";
import ViewButton from "../common/viewButton";
import EditButton from "../common/editButton";
import { t } from "i18next";

export default function DeviceEventsTableRow({
  row,
  handleClick,
  isItemSelected,
}) {
  const navigate = useNavigate();
  const device = useSelector(({ device }) => device);

  function handleClickView() {
    if (device) {
      const href = `/deviceSignals?Dashboard=MachineMultipleSignal&MachineName=${device.DeviceName}&device_uid=${device.DeviceID}&SensorID=${row.signal_idx}`;
      navigate(href);
    }
  }
  function handleClickEdit() {
    if (row && row.signal_descriptor_id) {
      const href = `/editDeviceSignal?signalDescriptorId=${row.signal_descriptor_id}`;
      navigate(href);
    }
  }
  return (
    <Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">{row.name}</StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">{row.eunit}</StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          {row.color ? (
            <Box
              sx={{
                width: "2rem",
                height: "2rem",
                backgroundColor: `rgb(${Math.floor(row.color / (256 * 256))},${
                  Math.floor(row.color / 256) % 256
                },${row.color % 256})`,
              }}
            />
          ) : (
            <StyledTypography variant="subtitle2">
              {t("NoColor")}
            </StyledTypography>
          )}
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">{row.tag}</StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">
            {row.sort_idx}
          </StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">
            {row.signal_idx}
          </StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">{row.min}</StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">{row.max}</StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">
            {row.low_thresh}
          </StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">
            {row.high_thresh}
          </StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">
            {row.description}
          </StyledTypography>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.name)}
          align="left"
        >
          <StyledTypography variant="subtitle2">{row.status}</StyledTypography>
        </TableCell>
        <TableCell align="left">
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <ViewButton
              marginRight={"4px"}
              onClick={(event) => handleClickView(event, row.name)}
            />
            <EditButton onClick={handleClickEdit} />
          </Box>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
