import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import moment from "moment";
import {
  GetHistoryTimerSumJSON,
  LatestDataColoring,
} from "../utils/systemInfo";
// import { useNavigate } from 'react-router-dom';

import OpsumService from "../api/opsum";
import TableGreenTypography from "../common/TableGreenTypography";
import StyledTypography from "../common/StyledTypography";
import OpsumTotalsComparisonProgress from "../common/opsumTotalsComparisonProgress";
import OpsumHistoryTotals from "../common/opsumHistoryTotals";
import ViewButton from "../common/viewButton";
import HistoryButton from "../common/historyButton";
import CollapseButton from "../common/collapseButton";

const opsumTotalsComparisonList = [
  {
    id: 0,
    tittle: "Powered",
    valueName: "powered",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "EngineRun",
    valueName: "engine_run",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "Tramming",
    valueName: "tramming",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "EnginerWorking",
    valueName: "high_rpm",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "Forward",
    valueName: "forward",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "Reverse",
    valueName: "reverse",
    valueLabel: "Hours",
    compareReverse: false,
  },
];

export default function MaintenanceTableRow({
  row,
  handleClick,
  handleArrowIcon,
  isItemSelected,
  labelId,
  dateFromBefore,
  dateFrom,
  dateto,
  isDevice,
  handleView,
  showNamedLocationOriginal,
}) {
  const [open, setOpen] = useState(false);
  // const navigate = useNavigate();
  const [deviceHistoryTotals, setDeviceHistoryTotals] = useState(undefined);
  const [compareDeviceHistoryTotals, setCompareDeviceHistoryTotals] =
    useState(undefined);
  const [productionJson, setProductionJson] = useState(undefined);
  const [scheduledTime, setScheduledTime] = useState(undefined);
  const { t } = useTranslation();
  const theme = useTheme();
  const organziation = useSelector(({ organziation }) => organziation);
  const timezoneMins = useSelector(({ timezone }) => timezone);

  function handleOpenCollapse() {
    if (!open) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      OpsumService.GetDeviceOpsumTimers({
        deviceID: row.DeviceID,
        deviceType: "nerohub",
        apiKey,
        historyFromDST: dateFrom,
        historyToDST: dateto,
      })
        .then((jsonCurrent) => {
          const DeviceHistoryTotals = GetHistoryTimerSumJSON(
            jsonCurrent.opsum.device[0].data,
            dateFrom,
            dateto
          );
          setDeviceHistoryTotals(DeviceHistoryTotals);
          setProductionJson(jsonCurrent.opsum.device[0].production);
          setScheduledTime(jsonCurrent.scheduled_time.scheduled);
          OpsumService.GetDeviceOpsumTimers({
            deviceID: row.DeviceID,
            deviceType: "nerohub",
            apiKey,
            historyFromDST: dateFromBefore,
            historyToDST: dateFrom,
          })
            .then((jsonPrevious) => {
              const CompareDeviceHistoryTotals = GetHistoryTimerSumJSON(
                jsonPrevious.opsum.device[0].data,
                dateFromBefore,
                dateFrom
              );
              setCompareDeviceHistoryTotals(CompareDeviceHistoryTotals);
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    setOpen(!open);
  }
  // const hanleViewHistoryPage = () => {
  //     const href = `/deviceHistory?Dashboard=${row.GrafanaDashboardUID}&MachineName=${row.DeviceName}&device_uid=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}&timerange`;
  //     // const newUrl = window.location
  //      navigate(href)
  // }
  return (
    <Fragment key={row.name}>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>
          <CollapseButton handleOpenCollapse={handleOpenCollapse} open={open} />
        </TableCell>
        <TableCell onClick={(event) => handleClick(event, row)} align="left">
          <StyledTypography variant="subtitle2">
            {row.DeviceName}
          </StyledTypography>{" "}
        </TableCell>
        <TableCell align="left" onClick={(event) => handleClick(event, row)}>
          <StyledTypography variant="subtitle2">
            {row.DeviceTitleDataName === "Device"
              ? row.DeviceSerial
              : `${row.DeviceSerialHex} (${row.DeviceSerial})`}
          </StyledTypography>
        </TableCell>
        <TableCell
          sx={{ height: "100%", color: theme.palette.custom.text }}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          <Box
            sx={{
              textAlign: "left",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              pl: "16px",
            }}
          >
            <div
              style={{ width: "40px" }}
              dangerouslySetInnerHTML={{
                __html: row.DeviceTypeIcon.replace(
                  "theme.palette.custom.text",
                  theme.palette.custom.text
                ),
              }}
            />{" "}
            <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
              {row.DeviceType}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row)}
          sx={{ height: "100%", color: theme.palette.custom.text }}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              pl: "16px",
            }}
          >
            <img width="40px" src={row.DeviceOEMIcon} alt="logo" />
            <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
              {row.DeviceOEM}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        {!isDevice && showNamedLocationOriginal && (
          <TableCell onClick={(event) => handleClick(event, row)} align="left">
            {row.NamedLocationOriginal === "Unknown" ? (
              <TableGreenTypography color="red" variant="subtitle2">
                {row.NamedLocationOriginal}
              </TableGreenTypography>
            ) : (
              <StyledTypography variant="subtitle2">
                {row.NamedLocationOriginal}
              </StyledTypography>
            )}
          </TableCell>
        )}

        <TableCell onClick={(event) => handleClick(event, row)} align="left">
          <TableGreenTypography
            color={LatestDataColoring(row.DeviceLastSeen, true)}
            variant="subtitle2"
          >
            {row.DeviceLastSeen !== "Not set" &&
            row.DeviceLastSeen !== " not seen"
              ? moment
                  .utc(row.DeviceLastSeen)
                  .add(timezoneMins, "minutes")
                  .format("YYYY-MM-DD HH:mm")
              : row.DeviceLastSeen}
          </TableGreenTypography>
        </TableCell>
        <TableCell align="center">
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <ViewButton onClick={(event) => handleView(event, row)} />
            <HistoryButton device={row} />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow key={row.name}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                sx={{ color: theme.palette.custom.text }}
                variant="h6"
                gutterBottom
                component="div"
              >
                {`${t("OPSUM DATA FOR")}:${row.DeviceName}`}
              </Typography>
              <OpsumHistoryTotals
                opsumTotalsComparisonList={opsumTotalsComparisonList}
                deviceHistoryTotals={deviceHistoryTotals}
                compareDeviceHistoryTotals={compareDeviceHistoryTotals}
                handleArrowIcon={handleArrowIcon}
              />
              <Box sx={{ textAlign: "center", width: "100%" }}>
                {productionJson && scheduledTime ? (
                  // <div style={{ width: '100%',height: '75px' }} dangerouslySetInnerHTML={{
                  //     __html: (GetDeviceOpsumTotalsComparisonProgressHTML(productionJson, scheduledTime))
                  // }} />
                  <OpsumTotalsComparisonProgress
                    ProductionJson={productionJson}
                    scheduled_time={scheduledTime}
                  />
                ) : null}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
