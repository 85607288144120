import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StyledTypography from "../common/StyledTypography";
import AssignButton from "../common/assignButton";
import OrganizationService from "../api/organization";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function DevToUserTableRow({ row, labelId, orgUid, iKey, setDisabledTab, setValue }) {
    const { t } = useTranslation();
    useEffect(() => {
        OrganizationService.GetApiKeys("", orgUid, "active", "MTIxMnwxfDN8MjE5OS0wMS0wMSAwMDowMDowMA").then((json) => {
            localStorage.setItem(
                "assignToOrg",
                JSON.stringify({
                    fullName: json.app[0].operation_full_name,
                    uid: json.app[0].operation_uid,
                    apiKey: json.app[0].key
                })
            );
            // orgKey = json.app[0].key
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const theme = useTheme();
    let email = "none";
    let mobile = "none";
    for (let i in row.contact) {
        if (row.contact[i].contact_type === "email_address" && row.contact[i].primary_contact === true) {
            email = row.contact[i].value;
        } else if (row.contact[i].contact_type === "tel_mobile" && row.contact[i].primary_contact === true) {
            mobile = row.contact[i].value;
        }
    }

    function handleAssign() {
        setDisabledTab(false);
        setValue(1);
        localStorage.setItem("assignToUser", row.profile_id)
    }

    let url = "../FakeUser.png";
    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={iKey} sx={{ cursor: "pointer" }}>
            <TableCell sx={{ height: "100%", color: theme.palette.custom.text }} component="th" id={labelId} scope="row" padding="none">
                <Box sx={{ textAlign: "center", width: "100%", display: "flex", flexFlow: "row" }}>
                    <span className="pfpSpan" style={{ width: "40px", height: "40px", backgroundImage: `url(${url})`, backgroundPosition: "center", backgroundSize: "cover" }}></span>
                    <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
                        {row.firstname}
                    </StyledTypography>{" "}
                </Box>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{row.lastname}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{email}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <StyledTypography variant="subtitle2">{mobile}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <Button className="viewUser" hidden onClick={handleAssign}>
                    <AssignButton type={"add"} title={"Assign"} text={`${t("Select")} ${t("User")}`} />
                </Button>
            </TableCell>
        </TableRow>
    );
}
