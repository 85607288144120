import { Box, Button, capitalize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { capitalize } from '@mui/material';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  GetDateTimeStringToday,
  getUrlVars,
  GetDateTimeString7DaysAgo,
  GetDateTimeString14DaysAgo,
} from "../utils/systemInfo";

import SearchInput from "../common/searchInput.js";
import DevicesService from "../api/devices";
import OrganizationService from "../api/organization";
// import HomePagesMenu from '../common/HomePagesMenu';
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import MachinesTableRow from "./machinesTableRow";
import { setMachinesData } from "../store/machinesSlice";
import Breadcrumbs from "../common/breadcrumbs";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV.js";
import GroupSelect from "../common/groupSelect.js";
import usePrevious from "../common/cutomHooks/usePrevious.js";
import StyledTypography from "../common/StyledTypography.js";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);
const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  minWidth: "fit-content",
  width: "150px",
  height: "32px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
const headCells = [
  {
    id: "DeviceName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sort: true,
  },
  {
    id: "DeviceSerial",
    numeric: false,
    disablePadding: false,
    label: "Serial",
    sort: true,
  },
  {
    id: "DeviceType",
    numeric: false,
    disablePadding: true,
    label: "MachineType",
    sort: true,
  },
  {
    id: "DeviceOEM",
    numeric: false,
    disablePadding: true,
    label: "oem",
    sort: true,
  },
  {
    id: "NamedLocationOriginal",
    numeric: false,
    disablePadding: false,
    label: "LastLocation",
    sort: true,
  },
  {
    id: "DeviceLastSeen",
    numeric: false,
    disablePadding: false,
    label: "LastSeen",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
function Machines() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organziation = useSelector(({ organziation }) => organziation);
  const machines = useSelector(({ machines }) => machines);
  const [opsumInputData, setOpsumInputData] = useState(undefined);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("DeviceLastSeen");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) ?? 5
  );
  const [searchText, setSearchText] = useState("");
  const [attributeFilter, setAttributeFilter] = useState({});

  const [selected, setSelected] = useState([]);
  const [loadingValue, setLoadingValue] = useState(true);
  const [rerenderItems, setRerenderItems] = useState(0);
  const pageName =
    window.location.href.split("/")[window.location.href.split("/").length - 1];
  const breadcrumbs = [
    { id: 0, name: "Home", href: "./" },
    { id: 1, name: capitalize(pageName), href: pageName },
  ];
  const DeviceTitleDataName = String(getUrlVars()["DeviceTitleDataName"]);
  const prevPageName = usePrevious(pageName);
  useEffect(() => {
    let currentorganization;
    if (
      organziation &&
      (organziation !== currentorganization || pageName !== prevPageName)
    ) {
      setLoadingValue(true);
      currentorganization = organziation;
      setOpsumInputData(null);
      const apiKey = organziation?.api_key_list?.[0]?.key;
      let DevicesClass;
      switch (pageName.toLowerCase()) {
        case "devices":
        case "mydevices":
          DevicesClass = { deviceType: [], source: "iot" };
          break;
        case "machines":
        case "mymachines":
          DevicesClass = { deviceType: ["nerohub", "nanohub"], source: "" };
          break;
        default:
          DevicesClass = { deviceType: ["", ""], source: "" };
          break;
      }
      if (pageName.includes("my")) {
        OrganizationService.GetPersonDeviceLink({
          operation_uid: organziation.uid,
          device_id: "",
          person_id: localStorage.getItem("assignToUser"),
          apiKey: apiKey,
        })
          .then((json) => {
            if (json?.person_device_link?.length) {
              const personDeviceLinkArray = json?.person_device_link.filter(
                (m) =>
                  m.link &&
                  (DevicesClass.deviceType.length === 0 ||
                    DevicesClass.deviceType.some((type) =>
                      m.device_type?.toLowerCase()?.includes(type)
                    )) &&
                  m.source.toLowerCase().includes(DevicesClass.source)
              );
              setOpsumInputData({
                json: personDeviceLinkArray,
                machineTypeFilter: "",
                machineType: DeviceTitleDataName || "machine",
              });
              dispatch(
                setMachinesData({
                  json: personDeviceLinkArray,
                  machineTypeFilter: "",
                  machineType: DeviceTitleDataName || "machine",
                })
              );
            }
            setLoadingValue(false);
          })
          .catch((error) => {
            if (process.env.NODE_ENV === 'development') console.log(error);
          });
      } else {
        DevicesService.getDevices({
          deviceID: "",
          DeviceType: DevicesClass.deviceType,
          Source: DevicesClass.source,
          apiKey,
          historyFromDST: "",
          historyToDST: GetDateTimeStringToday(),
        })
          .then((json) => {
            if (json && json.device_profile && json.device_profile.length > 0) {
              setOpsumInputData({
                json: json.device_profile,
                machineTypeFilter: "",
                machineType: DeviceTitleDataName || "machine",
              });
              dispatch(
                setMachinesData({
                  json: json.device_profile,
                  machineTypeFilter: "",
                  machineType: DeviceTitleDataName || "machine",
                })
              );
            }
            setLoadingValue(false);
          })
          .catch((errorr) => {
            if (process.env.NODE_ENV === 'development') console.log("getUserTypePermissionserrorr", errorr);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, rerenderItems]);
  //  }, [organziation]);

  const handleArrowIcon = (newValue, OldValue, reverse, fontSize) => {
    if (newValue > OldValue) {
      return reverse ? (
        <ArrowCircleDownRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleUpRoundedIcon
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else if (newValue < OldValue) {
      return reverse ? (
        <ArrowCircleUpRoundedIcon
          color={theme.palette.custom.text}
          //{reverse ? "#00B000" : "red"}
          sx={{
            minHeight: 48,
            color: "#00B000",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      ) : (
        <ArrowCircleDownRoundedIcon
          sx={{
            minHeight: 48,
            color: "red",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    } else {
      return (
        <RemoveCircleOutlineRoundedIcon
          sx={{
            minHeight: 48,
            color: "grey",
            fontSize: fontSize ? fontSize : 24,
          }}
        />
      );
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.DeviceID);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.DeviceID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  function handleView(event, row) {
    switch (row.DeviceTitleDataName) {
      case "Device":
        if (event.ctrlKey || event.button === 1) {
          window.open(
            `${window.location.origin}/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}&Dashboard=${row.GrafanaDashboardUID}&MachineName=${row.DeviceName}&device_uid=${row.DeviceID}`,
            "_blank"
          );
        } else if (event.type === "click") {
          navigate(
            `/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}&Dashboard=${row.GrafanaDashboardUID}&MachineName=${row.DeviceName}&device_uid=${row.DeviceID}`
          );
        }

        break;
      case "Machine":
      default:
        if (event.ctrlKey || event.button === 1) {
          window.open(
            `${window.location.origin}/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}`,
            "_blank"
          );
        } else if (event.type === "click") {
          navigate(
            `/device?device=${row.DeviceID}&DeviceTitleDataName=${row.DeviceTitleDataName}`
          );
        }
        break;
    }
  }
  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let opsumInputObj = opsumInputData;
    opsumInputObj.machineTypeFilter = "";
    opsumInputObj.newSearchText = newSearchText;
    dispatch(setMachinesData(opsumInputObj));
    setLoadingValue(false);
  }
  const visibleRows = useMemo(
    () => {
      if (machines && machines.length > 0) {
        return stableSort(
          machines.filter(
            (m) =>
              m[attributeFilter.attribute] === attributeFilter.value ||
              !attributeFilter.attribute
          ),
          getComparator(order, orderBy)
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, machines, attributeFilter]
    //[page, rowsPerPage, safety],
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // const productivityLabels = [t("Work Time"), t("Idle Time"), t("Offline Time")];
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        minHeight: "90vh",
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "block", sm: "center" },
          height: "64px",
        }}
      >
        <Box sx={{ display: { xs: "block", sm: "flex" } }}>
          <Box sx={{ width: "100%" }}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <Typography
              sx={{
                fontSize: "45px",
                mb: 0,
                color: theme.palette.custom.text,
              }}
              variant="h2"
              gutterBottom
            >
              {pageName?.toLocaleLowerCase().includes("devices")
                ? t("Devices")
                : t("Machines")}
            </Typography>
          </Box>
        </Box>
        <StyledButton
          variant="contained"
          onClick={() => setRerenderItems((r) => r + 1)}
        >
          {t("ClearBrowserCache")}
        </StyledButton>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchInput searchText={searchText} handleSearch={handleSearch} />
        {machines?.length && (
          <GroupSelect
            label={t("Select")}
            attributes={[
              {
                id: "DeviceType",
                name: `${
                  pageName === "devices" ? t("Device") : t("Machine")
                } Type`,
              },
              { id: "DeviceOEM", name: "OEM" },
            ]}
            rows={machines}
            onChange={(value, attribute) =>
              setAttributeFilter({ value, attribute })
            }
          />
        )}
      </Box>
      {/* <TextField
                value={searchText}
                onChange={handleSearch}
                margin="normal"
                fullWidth
                id="search"
                label={t('Search')}
                name="search"
                autoFocus
                sx={{
                    width: { xs: '100%', sm: "20%" },
                    borderColor: theme.palette.custom.borderColor,
                    borderRadius: '11px',
                    backgroundColor: theme.palette.custom.light

                }}
            /> */}
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //  size={'medium'}
          >
            <EnhancedTableHead
              isDevice={pageName === "devices"}
              headCells={headCells.filter(
                (hc) =>
                  hc.id !== "NamedLocationOriginal" ||
                  visibleRows.find(
                    (vr) =>
                      vr.NamedLocationOriginal &&
                      vr.NamedLocationOriginal !== "Unknown"
                  )
              )}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {machines && machines.length > 0 && visibleRows.length ? (
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.DeviceID);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <MachinesTableRow
                      key={index}
                      row={row}
                      isDevice={pageName === "devices"}
                      handleClick={handleClick}
                      handleArrowIcon={handleArrowIcon}
                      dateFromBefore={GetDateTimeString14DaysAgo()}
                      dateFrom={GetDateTimeString7DaysAgo()}
                      dateto={GetDateTimeStringToday()}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleView={handleView}
                      showNamedLocationOriginal={visibleRows.find(
                        (vr) =>
                          vr.NamedLocationOriginal &&
                          vr.NamedLocationOriginal !== "Unknown"
                      )}
                    />
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={machines ? machines.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom.borderColor,
          color: "white",
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
          float: "right",
          width: "min-content",
          whiteSpace: "nowrap",
          padding: "1rem",
        }}
        onClick={() =>
          exportTableToCSV(
            headCells.filter(
              (hc) =>
                hc.id !== "NamedLocationOriginal" ||
                visibleRows.find(
                  (vr) =>
                    vr.NamedLocationOriginal &&
                    vr.NamedLocationOriginal !== "Unknown"
                )
            ),
            visibleRows,
            "Machines List"
          )
        }
      >
        {t("Download List (*.csv)")}
      </Button>
    </Box>
  );
}

export default Machines;
