/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setTimezone = createAsyncThunk('timezone/setTimezone', async (timezone, { dispatch, getState }) => {
  localStorage.setItem("timezone", timezone);  
  return timezone;
});



const initialState = 0;

const timezoneSlice = createSlice({
  name: 'timezone',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setTimezone.fulfilled, (state, action) => action.payload);
  },
});

export const selectTimezone = ({ timezone }) => timezone;

export default timezoneSlice.reducer;
