import BarChartIcon from "@mui/icons-material/BarChart";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

function HistoryButton({ device }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const hanleViewHistoryPage = () => {
    const href = `/deviceHistory?Dashboard=${device.GrafanaDashboardUID}&MachineName=${device.DeviceName}&device_uid=${device.DeviceID}&DeviceTitleDataName=${device.DeviceTitleDataName}&timerange`;
    // const newUrl = window.location
    navigate(href);
  };
  return (
    <Box
      onClick={hanleViewHistoryPage}
      sx={{
        backgroundColor: theme.palette.custom.buttonColor,
        borderRadius: "4px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
        // display: "flex",
        // justifyContent: "center",
        width: "46px",
        height: "36px",
        padding: "5px 10px",
        marginLeft: "5px",
        "&:hover": {
          backgroundColor: theme.palette.custom.buttonHoverColor,
          color: theme.palette.custom.buttonHoverTextColor,
          boxShadow: "none",
        },
      }}
    >
      <BarChartIcon
        onClick={hanleViewHistoryPage}
        sx={{
          color: theme.palette.custom.buttonTextColor,
          fontSize: 24,
          "&:hover": {
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
        }}
      />
    </Box>
  );
}

export default HistoryButton;
