/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setDashboardsOrganizationLinkings = createAsyncThunk(
  "dashboardsOrganizationLinkings/setDashboardsOrganizationLinkings",
  async (inputData, { dispatch, getState }) => {
    const data = inputData.data;
    if (inputData.newSearchText?.length && inputData.data?.length) {
        const searchText = inputData.newSearchText.toLowerCase();
        return data.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return data;
  }
);

const initialState = null;

const dashboardsOrganizationLinkingsSlice = createSlice({
  name: "dashboardsOrganizationLinkings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setDashboardsOrganizationLinkings.fulfilled, (state, action) => action.payload);
  },
});

export const selectDashboardsOrganizationLinkings = ({
  dashboardsOrganizationLinkings,
}) => dashboardsOrganizationLinkings;

export default dashboardsOrganizationLinkingsSlice.reducer;
