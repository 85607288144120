import { useTheme } from "@mui/material/styles";
import { useEffect, useState, useRef } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StyledTypography from "../common/StyledTypography";
import ProjectService from "../api/project";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import ViewButton from "../common/viewButton.js";
import CommentButton from "../common/commentButton.js";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Off from "../common/images/robot_icons/light_off.svg";
import Green from "../common/images/robot_icons/green_light_on.svg";
import Red from "../common/images/robot_icons/red_light_on.svg";
import Yellow from "../common/images/robot_icons/yellow_light_on.svg";
import Orange from "../common/images/robot_icons/orange_light_on.svg";

const CustomSelect = styled(Select)(({ theme }) => ({
    "background": theme.palette.custom.inputBackgroundColor,
    "height": "36px",
    "width": "100%",
    "minWidth": "100px",
    "color": theme.palette.custom.text,
    "outlineColor": theme.palette.custom.borderColor,
    "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.custom.borderColor,
        color: theme.palette.custom.borderColor
    },
    "&.Mui-focused": {
        color: theme.palette.custom.text
    },
    "&:focused": {
        color: theme.palette.custom.borderColor
    },
    "& .MuiList-root": {
        backgroundColor: theme.palette.custom.inputBackgroundColor
    }
}));

export default function MachineDocumentationTableRow({ row, labelId, orgUid, handleChangeDocsData, iKey, projectID, hidden }) {
    const organziation = useSelector(({ organziation }) => organziation);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    const [openSelects, setOpenSelects] = useState({});
    const [imageSources, setImageSources] = useState({});
    const [comment, setComment] = useState(row.latest_comment);
    const imageRef = useRef(null);
    const navigate = useNavigate();
    const theme = useTheme();
    const handleChange = (event) => {
        const { name, value } = event.target;
        const col = name.split("?")[1];
        const paramID = name.split("?")[0];
        handleChangeDocsData(row, value, name, row.tasks);
        updateImageSource(paramID, value, col);
        ProjectService.SetParamVal("", name, value, "active", apiKey)
    };

    const handleImageClick = (event) => {
        let taskParamId = event.target.id;
        setOpenSelects((prevState) => ({
            ...prevState,
            [taskParamId]: !prevState[taskParamId]
        }));
    };

    const updateImageSource = (taskParamId, value, col) => {
        let newSrc = "robot_icons/light_off.svg"; // default image
        if (col === "Risk") {
            switch (value) {
                case 0:
                    newSrc = Off;
                    break;
                case 1:
                    newSrc = Green;
                    break;
                case 2:
                    newSrc = Yellow;
                    break;
                case 3:
                    newSrc = Orange;
                    break;
                case 4:
                    newSrc = Red;
                    break;
                default:
                    newSrc = Off;
            }
        } else {
            switch (value) {
                case 0:
                    newSrc = Off;
                    break;
                case 1:
                    newSrc = Green;
                    break;
                case 2:
                    newSrc = Red;
                    break;

                default:
                    newSrc = Off;
            }
        }

        setImageSources((prevState) => ({
            ...prevState,
            [taskParamId]: newSrc
        }));
    };

    const CustomSelectImage = styled(Select)(({ theme, name }) => ({
        "background": "transparent",
        "height": "40px",
        "width": "40px",
        "color": "transparent",
        "outlineColor": "transparent",
        "backgroundImage": `url(${imageSources[name.toString()]})`,
        "backgroundRepeat": "no-repeat",
        "&root": {
            borderColor: "transparent",
            color: "transparent",
            border: "0"
        },
        "&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
            color: "transparent",
            border: "0"
        },
        "&.Mui-focused": {
            color: "transparent"
        },
        "&:focused": {
            color: "transparent"
        },
        "&:before": {
            display: "none",
            borderColor: "transparent",
            color: "transparent",
            border: "0"
        },
        "& .MuiList-root": {
            backgroundColor: "transparent"
        },
        "&:after": {
            borderColor: "transparent",
            color: "transparent",
            border: "0",
            display: "none"
        }
    }));

    const GetSelectType = (e) => {
        if (hidden) {
            if (e.name === "Risk") {
                return (
                    <CustomSelectImage
                        key={Math.floor(Math.random() * 1000000000)}
                        variant="standard"
                        labelId="Status"
                        value={e.value}
                        name={e.task_param_id}
                        IconComponent={() => null}
                        open={!!openSelects[e.task_param_id]}
                        onClose={() =>
                            setOpenSelects((prevState) => ({
                                ...prevState,
                                [e.task_param_id]: false
                            }))
                        }
                        onOpen={() =>
                            setOpenSelects((prevState) => ({
                                ...prevState,
                                [e.task_param_id]: true
                            }))
                        }
                        onChange={handleChange}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    "backgroundColor": theme.palette.custom.inputBackgroundColor,
                                    "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: theme.palette.primary.main
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: theme.palette.custom.buttonHoverColor
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                        backgroundColor: theme.palette.custom.backgroundColor2
                                    }
                                }
                            }
                        }}>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={0}>
                            Not Set
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={1}>
                            No Risk
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={2}>
                            Low Risk
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={3}>
                            Medium Risk
                        </MenuItem>
                        <MenuItem value={4}>High Risk</MenuItem>
                    </CustomSelectImage>
                );
            } else {
                return (
                    <CustomSelectImage
                        key={Math.floor(Math.random() * 1000000000)}
                        variant="standard"
                        labelId="Status"
                        value={e.value}
                        name={e.task_param_id}
                        IconComponent={() => null}
                        open={!!openSelects[e.task_param_id]}
                        onClose={() =>
                            setOpenSelects((prevState) => ({
                                ...prevState,
                                [e.task_param_id]: false
                            }))
                        }
                        onOpen={() =>
                            setOpenSelects((prevState) => ({
                                ...prevState,
                                [e.task_param_id]: true
                            }))
                        }
                        onChange={handleChange}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    "backgroundColor": theme.palette.custom.inputBackgroundColor,
                                    "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: theme.palette.primary.main
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: theme.palette.custom.buttonHoverColor
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                        backgroundColor: theme.palette.custom.backgroundColor2
                                    }
                                }
                            }
                        }}>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={0}>
                            Not Set
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={1}>
                            Yes
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={2}>
                            No
                        </MenuItem>
                    </CustomSelectImage>
                );
            }
        } else {
            if (e.name === "Risk") {
                return (
                    <CustomSelect
                        key={Math.floor(Math.random() * 1000000000)}
                        labelId="Status"
                        value={e.value}
                        name={e.task_param_id}
                        open={hidden ? false : !!openSelects[e.task_param_id]}
                        onClose={() =>
                            setOpenSelects((prevState) => ({
                                ...prevState,
                                [e.task_param_id]: false
                            }))
                        }
                        onOpen={() =>
                            setOpenSelects((prevState) => ({
                                ...prevState,
                                [e.task_param_id]: true
                            }))
                        }
                        onChange={handleChange}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    "backgroundColor": theme.palette.custom.inputBackgroundColor,
                                    "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: theme.palette.primary.main
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: theme.palette.custom.buttonHoverColor
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                        backgroundColor: theme.palette.custom.backgroundColor2
                                    }
                                }
                            }
                        }}>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={0}>
                            Not Set
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={1}>
                            No Risk
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={2}>
                            Low Risk
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={3}>
                            Medium Risk
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={4}>
                            High Risk
                        </MenuItem>
                    </CustomSelect>
                );
            } else {
                return (
                    <CustomSelect
                        key={Math.floor(Math.random() * 1000000000)}
                        labelId="Status"
                        value={e.value}
                        name={e.task_param_id}
                        open={hidden ? false : !!openSelects[e.task_param_id]}
                        onClose={() =>
                            setOpenSelects((prevState) => ({
                                ...prevState,
                                [e.task_param_id]: false
                            }))
                        }
                        onOpen={() =>
                            setOpenSelects((prevState) => ({
                                ...prevState,
                                [e.task_param_id]: true
                            }))
                        }
                        onChange={handleChange}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    "backgroundColor": theme.palette.custom.inputBackgroundColor,
                                    "& .MuiMenuItem-root.Mui-selected": {
                                        backgroundColor: theme.palette.primary.main
                                    },
                                    "& .MuiMenuItem-root:hover": {
                                        backgroundColor: theme.palette.custom.buttonHoverColor
                                    },
                                    "& .MuiMenuItem-root.Mui-selected:hover": {
                                        backgroundColor: theme.palette.custom.backgroundColor2
                                    }
                                }
                            }
                        }}>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={0}>
                            Not Set
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={1}>
                            Yes
                        </MenuItem>
                        <MenuItem key={Math.floor(Math.random() * 1000000000)} value={2}>
                            No
                        </MenuItem>
                    </CustomSelect>
                );
            }
        }
    };

    useEffect(() => {
        row.tasks.forEach((task) => {
            updateImageSource(task.task_param_id.toString(), Number(task.value), task.name);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleCommentAdd() {
        let inputValue;
        const { value: comment } = await Swal.fire({
            title: "Add Comment",
            showCancelButton: true,
            confirmButtonText: "Post",
            color: theme.palette.custom.text,
            background: theme.palette.custom.inputBackgroundColor,
            input: "text",
            inputLabel: "Add Comment",
            inputValue,
            inputValidator: (value) => {
                if (!value) {
                    return "You need to write something!";
                }
            }
        });
        if (comment) {
            await ProjectService.SetGroupComment(row.task_group_id, comment, "active", apiKey).then((json) => {
                if (json) {
                    setComment(comment);
                }
            });
        }
    }
    const handleNavigate = () => {
        navigate(`/addMachineDocs?projectId=${projectID}&groupId=${row.task_group_id}`);
    };
    const handleCommentClick = () => {
        navigate(`/machineComments?groupId=${row.task_group_id}&name=${row.name}`);
    };

    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={iKey} sx={{ cursor: "pointer" }}>
            {row.tasks.map((e) =>
                e.name === "U/G ID Number" || e.name === "Model" || e.name === "Manufacturer" || e.name === "Equipment Type" ? (
                    <TableCell align="left" key={e.task_param_id}>
                        <StyledTypography sx={{ ml: "8px" }} variant="subtitle2">
                            {e.value}
                        </StyledTypography>
                    </TableCell>
                ) : (
                    <TableCell align="left" key={e.task_param_id}>
                        <FormControl fullWidth sx={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                            {!hidden && <img ref={imageRef} src={imageSources[e.task_param_id.toString()]} alt="status_icon" style={{ width: "40px", height: "40px", cursor: "pointer" }} onClick={handleImageClick} id={`${e.task_param_id}`} />}
                            {GetSelectType(e)}
                        </FormControl>
                    </TableCell>
                )
            )}
            <TableCell align="left" onClick={handleCommentClick}>
                <StyledTypography variant="subtitle2">{comment}</StyledTypography>
            </TableCell>
            <TableCell align="left">
                <ViewButton onClick={handleNavigate} />
                <CommentButton onClick={handleCommentAdd} />
            </TableCell>
        </TableRow>
    );
}
