/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setInputOptions = createAsyncThunk('inputOptions/setInputOptions', async (inputOptions, { dispatch, getState }) => {

  return inputOptions;
});



const initialState = null;

const inputOptionsSlice = createSlice({
  name: 'inputOptions',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setInputOptions.fulfilled, (state, action) => action.payload);
  },
});

export const selectInputOptions = ({ inputOptions }) => inputOptions;

export default inputOptionsSlice.reducer;
