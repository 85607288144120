import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import StyledTypography from "../common/StyledTypography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { setDashboardsOrganizationLinking } from "../store/dashboardsOrganizationLinkingSlice";

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  width: " 50px",
  height: "32px",
  margin: "0px",
  color: theme.palette.custom.buttonTextColor,
  backgroundColor: theme.palette.custom.buttonColor,
  borderColor: theme.palette.custom.borderColor,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.custom.buttonHoverColor,
    color: theme.palette.custom.buttonHoverTextColor,
    boxShadow: "none",
  },
}));

export default function GrafanaOrganizationLinkingTableRow({
  row,
  handleClick,
  isItemSelected,
  index,
}) {
  const navigate = useNavigate();
  const currenthheme = useSelector(({ theme }) => theme);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleEditButton = () => {
    dispatch(setDashboardsOrganizationLinking(row));
    navigate(`/addEditOrganizationLink`);
  };
  return (
    <Fragment>
      <TableRow
        hover
        onClick={(event) => handleClick(event, index)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={index}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell align="left">
          <Avatar
            sx={{ mr: 1 }}
            alt="Remy Sharp"
            src={`${process.env.PUBLIC_URL}/images/dashboards/${row.grafana_dashboard_name_backend}_${currenthheme}.png`}
          />
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.grafana_dashboard_name}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.grafana_dashboard_id}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.operation_name}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">
            {row.operation_profile_id}
          </StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledTypography variant="subtitle2">{row.status}</StyledTypography>
        </TableCell>
        <TableCell align="left">
          <StyledButton
            variant="contained"
            sx={{ mb: "4px" }}
            onClick={handleEditButton}
          >
            {t("Edit")}
          </StyledButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
