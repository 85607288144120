import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import Loading from "../common/loading";
import EnhancedTableHead from "../common/EnhancedTableHead";
import OrganizationService from "../api/organization";
import DeviceTableRow from "./deviceAssignTableRow";
import { GetMachineTypeIcon } from "../utils/systemInfo";
import { stableSort, getComparator } from "../utils/systemInfo";
import { setAssignDeviceData } from "../store/assignDevicesSlice.js";
import SearchInput from "../common/searchInput.js";
import exportTableToCSV from "../common/customFunctions/exportTableToCSV.js";
import StyledTypography from "../common/StyledTypography.js";
import Breadcrumbs from "../common/breadcrumbs";

const StyledTablePagination = styled((props) => <TablePagination {...props} />)(
  ({ theme }) => ({
    color: theme.palette.custom.text,
    "& .MuiTablePagination-selectIcon": {
      color: theme.palette.custom.text,
    },
  })
);

const deviceHead = [
  {
    id: "DeviceName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true,
  },
  {
    id: "DeviceSerial",
    numeric: false,
    disablePadding: false,
    label: "Serial",
    sort: true,
  },
  {
    id: "DeviceType",
    numeric: false,
    disablePadding: false,
    label: "MachineType",
    sort: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    sort: true,
  },
  {
    id: "DeviceLastSeen",
    numeric: false,
    disablePadding: false,
    label: "LastSeen",
    sort: true,
  },
  {
    id: "view",
    numeric: false,
    disablePadding: false,
    label: "View",
    sort: false,
  },
];
const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Developer Users" },
  { id: 2, name: "Assign Devices", href: "assignDevices" },
];
export default function AssignDevices() {
  const dispatch = useDispatch();
  const organziation = useSelector(({ organziation }) => organziation);
  const machines = useSelector(({ assignDevice }) => assignDevice);
  const [opsumInputData, setOpsumInputData] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DeviceName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Number(localStorage.getItem("rowsPerPage")) !== undefined
      ? Number(localStorage.getItem("rowsPerPage"))
      : 5
  );
  const [searchText, setSearchText] = useState("");
  const [loadingValue, setLoadingValue] = useState(true);
  const [orgUid, setOrgUid] = useState(undefined);
  const visibleRows = useMemo(
    () => {
      if (machines && machines.length > 0) {
        return stableSort(machines, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
      } else {
        return [];
      }
    },
    [order, orderBy, page, rowsPerPage, machines]
    //[page, rowsPerPage, safety],
  );

  function handleSearch(ev) {
    setPage(0);
    setLoadingValue(true);
    const newSearchText = ev.target.value;
    setSearchText(newSearchText);
    let opsumInputObj = opsumInputData;
    opsumInputObj.newSearchText = newSearchText;
    dispatch(setAssignDeviceData(opsumInputObj));
    setLoadingValue(false);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangeDeviceData = (row, isLink) => {
    let newDeviceList = [];
    opsumInputData?.json?.forEach((e) => {
      if (e.DevicePID === row.DevicePID) {
        let deviceObj = {
          MachineType: row.MachineType,
          DeviceName: row.DeviceName,
          DeviceID: row.DeviceID,
          DeviceLastSeen: row.DeviceLastSeen,
          DeviceSerial: row.DeviceSerial,
          DeviceType: row.DeviceType,
          DeviceTypeIcon: row.DeviceTypeIcon,
          DevicePID: row.DevicePID,
          status: isLink ? "Assigned" : "Unassigned",
        };
        newDeviceList.push(deviceObj);
      } else {
        newDeviceList.push(e);
      }
    });
    dispatch(
      setAssignDeviceData({ json: newDeviceList, newSearchText: searchText })
    );
    setOpsumInputData({ json: newDeviceList });
  };
  useEffect(() => {
    const defaultOrganization = JSON.parse(
      localStorage.getItem("defaultOrganization")
    );
    if (defaultOrganization) {
      const apiKey = defaultOrganization?.api_key_list?.[0]?.key;
      setOrgUid(defaultOrganization.uid);
      OrganizationService.GetAssignDeviceToOrg({
        operation_uid: orgUid,
        device_id: "",
        apiKey: apiKey,
      })
        .then((json) => {
          if (
            json &&
            json.operation_device_profile_link &&
            json.operation_device_profile_link.length > 0
          ) {
            const deviceList = PopulateAssignDevicesData(json);
            setOpsumInputData({ json: deviceList });
            dispatch(setAssignDeviceData({ json: deviceList }));
          } else {
            dispatch(setAssignDeviceData({ json: [] }));
          }
          setLoadingValue(false);
        })
        .catch((error) => {
          if (process.env.NODE_ENV === 'development') console.log(error);
        });
    }
    return () => {
      setOpsumInputData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organziation]);

  function PopulateAssignDevicesData(json) {
    const deviceArr = [];
    const jsonThisWeek = json.operation_device_profile_link;
    for (let i in jsonThisWeek) {
      if (
        jsonThisWeek[i].link !== null &&
        jsonThisWeek[i].link !== undefined &&
        JSON.parse(localStorage.getItem("assignToOrg")).uid ===
          jsonThisWeek[i].link.operation_profile_uid
      ) {
        let assignDeviceDataObj = {
          MachineType: "",
          DeviceName: "",
          DeviceID: "",
          DeviceLastSeen: "",
          DeviceSerial: "",
          DeviceType: "",
          DeviceTypeIcon: "",
          DevicePID: "",
          status: "Unassigned",
        };
        assignDeviceDataObj.DeviceName = jsonThisWeek[i].name;
        assignDeviceDataObj.DeviceID = jsonThisWeek[i].device_uid;
        assignDeviceDataObj.DeviceSerial = jsonThisWeek[i].serial;
        assignDeviceDataObj.DevicePID = jsonThisWeek[i].device_profile_id;
        assignDeviceDataObj.DeviceLastSeen = jsonThisWeek[i].latest_data_dst;
        if (
          jsonThisWeek[i].link.status === "active" ||
          jsonThisWeek[i].link.status === "linked" ||
          jsonThisWeek[i].link.status === "Link successful"
        )
          assignDeviceDataObj.status = "Assigned";
        if (
          jsonThisWeek[i].device_profile_id ===
          "20210112114024783_Qp7KsXsAcfrtVoz04"
        )
        // DeviceType = String(DevicesArray[index].profile.vehicle_type);
        // if (DeviceType == "") DeviceType = "Unknown";
        assignDeviceDataObj.MachineType = jsonThisWeek[i].machine_type;
        // let DeviceType = String(jsonThisWeek[i].vehicle_type );
        assignDeviceDataObj.DeviceType = jsonThisWeek[i].device_type;
        if (assignDeviceDataObj.DeviceType === "")
          assignDeviceDataObj.DeviceType = "Unknown";
        const DeviceTypeIcon = GetMachineTypeIcon(
          assignDeviceDataObj.DeviceType
        );
        assignDeviceDataObj.DeviceTypeIcon = DeviceTypeIcon;

        deviceArr.push(assignDeviceDataObj);
      } else {
        let assignDeviceDataObj = {
          MachineType: "",
          DeviceName: "",
          DeviceID: "",
          DeviceLastSeen: "",
          DeviceSerial: "",
          DeviceType: "",
          DeviceTypeIcon: "",
          DevicePID: "",
          status: "Unassigned",
        };
        assignDeviceDataObj.DeviceName = jsonThisWeek[i].name;
        assignDeviceDataObj.DeviceID = jsonThisWeek[i].device_uid;
        assignDeviceDataObj.DeviceSerial = jsonThisWeek[i].serial;
        assignDeviceDataObj.DevicePID = jsonThisWeek[i].device_profile_id;
        assignDeviceDataObj.DeviceLastSeen = jsonThisWeek[i].latest_data_dst;

        // DeviceType = String(DevicesArray[index].profile.vehicle_type);
        // if (DeviceType == "") DeviceType = "Unknown";
        assignDeviceDataObj.MachineType = jsonThisWeek[i].machine_type;
        // let DeviceType = String(jsonThisWeek[i].vehicle_type );
        assignDeviceDataObj.DeviceType = jsonThisWeek[i].device_type;
        if (assignDeviceDataObj.DeviceType === "")
          assignDeviceDataObj.DeviceType = "Unknown";
        const DeviceTypeIcon = GetMachineTypeIcon(
          assignDeviceDataObj.DeviceType
        );
        assignDeviceDataObj.DeviceTypeIcon = DeviceTypeIcon;

        deviceArr.push(assignDeviceDataObj);
      }
    }
    return deviceArr;
  }

  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        height: "100%",
        overflow: "auto",
      }}
    >
      <Box sx={{ display: { xs: "block", sm: "flex" } }}>
        <Box sx={{ width: "100%" }}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <Typography
            sx={{
              fontSize: "45px",
              mb: 0,
              color: theme.palette.custom.text,
            }}
            variant="h2"
            gutterBottom
          >
            {t("Assign")} {t("Devices")}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <SearchInput searchText={searchText} handleSearch={handleSearch} />
      {loadingValue ? (
        <Box sx={{ height: "30vh", pt: "10%" }}>
          <Loading />
        </Box>
      ) : (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            //  size={'medium'}
          >
            <EnhancedTableHead
              headCells={deviceHead}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              hideCheck
            />

            <TableBody>
              {machines && machines.length > 0 && visibleRows.length ? (
                visibleRows.map((row, index) => {
                  return (
                    <DeviceTableRow
                      handleChangeData={handleChangeDeviceData}
                      iKey={index}
                      key={index}
                      row={row}
                      orgUid={orgUid}
                    ></DeviceTableRow>
                  );
                })
              ) : (
                <StyledTypography>{t("No entries found")}</StyledTypography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <StyledTablePagination
        SelectProps={{
          inputProps: {
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: theme.palette.secondary.dark,
                  color: theme.palette.custom.text,
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: theme.palette.custom.borderColor,
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: "blue",
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: "blue",
                  },
                },
              },
            },
          },
        }}
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={machines ? machines.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.custom.buttonColor,
          color: theme.palette.custom.buttonTextColor,
          "&:hover": {
            backgroundColor: theme.palette.custom.buttonHoverColor,
            color: theme.palette.custom.buttonHoverTextColor,
            boxShadow: "none",
          },
          float: "right",
          width: "min-content",
          whiteSpace: "nowrap",
          padding: "1rem",
        }}
        onClick={() =>
          exportTableToCSV(deviceHead, visibleRows, "Assign Devices List")
        }
      >
        {t("Download List (*.csv)")}
      </Button>
    </Box>
  );
}
