import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, InputAdornment } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import ProfilePictureSelect from "../common/ProfileSelect/button";
import Button from "@mui/material/Button";
import ProfileService from "../api/profile";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import TimezonePicker from "../common/timezonePicker";
import SelectFields from "../common/selectFields";
import CustomTextField from "../common/CustomTextField";
import Breadcrumbs from "../common/breadcrumbs";
import { showMessage } from "../store/messageSlice";
import { setUser } from "../store/userSlice";
import FakeUser from "../FakeUser.png";
import AlertDialog from "../common/AlertDialog";
import { validateEmail } from "../utils/systemInfo";

const breadcrumbs = [
  { id: 0, name: "Home", href: "./" },
  { id: 1, name: "Profile", href: "myProfile" },
];
const Languages = [
  {
    value: "af",
    label: "afrikaans",
  },
  {
    value: "en",
    label: "english",
  },
  {
    value: "fr",
    label: "french",
  },
  {
    value: "de",
    label: "german",
  },
  {
    value: "it",
    label: "italian",
  },
  {
    value: "kl",
    label: "klingon",
  },
  {
    value: "mn",
    label: "mongolian",
  },
  {
    value: "ru",
    label: "russian",
  },
  {
    value: "es",
    label: "spanish",
  },
];

function handleDelete(name, lName, userProfileId) {
  ProfileService.SetUserProfile(userProfileId, name, lName, "removed");
}

export default function MyProfile() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const organziation = useSelector(({ organziation }) => organziation);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [lName, setLName] = useState("");
  const [lang, setLang] = useState("");
  const [org, setOrg] = useState("");
  const [uType, setUType] = useState("");
  const [userTypeId, setUserTypeId] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileContactUid, setMobileContactUid] = useState("");
  const [email, setEmail] = useState("");
  const [emailContactUid, setEmailContactUid] = useState("");
  const [url, setUrl] = useState("");
  const [timezone, setTimezone] = useState("");
  const [measurementSystem, setMeasurementSystem] = useState("");
  const [profileSaved, setProfileSaved] = useState(false);
  const [attributesSaved, setAttributesSaved] = useState(false);
  const [pictureSaved, setPictureSaved] = useState(false);
  const [mobileSaved, setMobileSaved] = useState(false);
  const [emailSaved, setEmailSaved] = useState(false);
  const [openDeleteAlertDialog, setOpenDeleteAlertDialog] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const user = useSelector(({ user }) => user);
  useEffect(() => {
    setLang(i18n.language);
    const apiKey = organziation?.api_key_list?.[0]?.key;
    const profileInfo = {
      userProfileID: user?.profileId,
      userProfileUID: "",
      status: "active",
      apiKey: apiKey,
    };
    ProfileService.GetUserProfile(profileInfo).then((json) => {
      const userJSON = json.person_profile?.[0];
      if (userJSON) {
        ProfileService.GetUserProfilePicture({ userProfileID: userJSON.profile_id, apiKey }).then((json) => {
          setUrl(json.person_profile_image
            ?.sort((a, b) => new Date(b?.updated_timestamp) - new Date(a?.updated_timestamp))
            ?.find(img => img.image_type === "profile_image" && img.status === "active")
            ?.public_url);
        });
        ProfileService.GetUserProfileAttributes({ userProfileID: userJSON.profile_id, apiKey }).then((json) => {
          if (json.success && json.count > 0) {
            setTimezone(
              json?.profile_attribute?.find(
                (a) => a.channel === "timezone_default" && a.operation_profile_id === organziation.uid
              )?.value
            );
            setMeasurementSystem(
              json?.profile_attribute?.find(
                (a) => a.channel === "measure_default" && a.operation_profile_id === organziation.uid
              )?.value
            );
          }
        });
        ProfileService.GetUserType({ user_type_id: userJSON.user_type_uid, apiKey: apiKey, }).then((json) => {
          setUType(json?.app_user_type?.[0]?.name);
        });
        const mobileContact = userJSON.contact?.find(c => c.contact_type === "tel_mobile" && c.primary_contact);
        setMobile(mobileContact?.value);
        setMobileContactUid(mobileContact?.contact_uid);
        const emailContact = userJSON.contact?.find(c => c.contact_type === "email_address" && c.primary_contact);
        setEmail(emailContact?.value);
        setEmailContactUid(emailContact?.contact_uid);
        setOrg(organziation.full_name);
        setName(userJSON.firstname);
        setLName(userJSON.lastname);
        setUserTypeId(userJSON.user_type_uid);//is used when saving user to local storage, so don't remove
      }
      else {
        //should logout
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleSave() {
    const apiKey = organziation?.api_key_list?.[0]?.key;
    if (document.getElementById("file").files.length > 0) {
      ProfileService.SetUserProfilePicture(user.profileId, "pfpForm", apiKey).then((response) => {
        if (response?.count >= 1) setPictureSaved(true);
      });
    } else {
      setPictureSaved(true);
    }
    ProfileService.SetUserProfile(user.profileId, name, lName, "active", "").then(
      (response) => {
        if (response?.data?.count >= 1) setProfileSaved(true);
      }
    );
    ProfileService.SetUserProfileAttributes({
      attributes: [
        {
          channel: "timezone_default",
          status: "active",
          value: timezone,
        },
        {
          channel: "measure_default",
          status: "active",
          value: measurementSystem,
        },
      ],
      person_profile_id: user.profileId,
      apiKey,
      operation_profile_id: organziation.uid,
    }).then((response) => {
      if (response?.count >= 1) setAttributesSaved(true);
    });
    ProfileService.SetUserContact({ contact_uid: mobileContactUid, person_profile_uid: user.profileUid, contact_type: "tel_mobile", value: mobile })
      .then((response) => {
        if (response?.data?.count >= 1) {
          setEmailSaved(true);
        }
      });
    ProfileService.SetUserContact({ contact_uid: emailContactUid, person_profile_uid: user.profileUid, contact_type: "email_address", value: email })
      .then((response) => {
        if (response?.data?.count >= 1) {
          setMobileSaved(true);
        }
      });
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    if (profileSaved && attributesSaved && pictureSaved && emailSaved && mobileSaved) {
      dispatch(
        showMessage({
          message: t("successfully saved"), //text or html
          autoHideDuration: 30000, //ms
          anchorOrigin: {
            vertical: "top", //top bottom
            horizontal: "center", //left center right
          },
          variant: "success", //success error info warning null
        })
      );
      setProfileSaved(false);
      setAttributesSaved(false);
      setPictureSaved(false);
      setMobileSaved(false);
      setEmailSaved(false);
      dispatch(
        setUser({
          ...user,
          lastName: lName,
          firstName: name,
          email: email,
          mobileNumber: mobile,
          userType: userTypeId,
        })
      );
    }
    else {
      //show error message
      // but you have to make sure what's wrong first
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSaved, attributesSaved, pictureSaved, emailSaved, mobileSaved]);
  const handleChangeLanguage = (value) => {
    setLang(value);
  };
  const handleCloseDeleteAlertDialog = () => {
    setOpenDeleteAlertDialog(false);
  };
  return (
    <Box
      sx={{
        p: "12px",
        borderRadius: "16px",
        border: `1px solid ${theme.palette.custom.borderColor}`,
      }}
      style={{
        height: "100%",
      }}
    >
      <Box>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Typography
          sx={{
            fontSize: "45px",
            mb: 0,
            color: theme.palette.custom.borderColor,
          }}
          variant="h2"
          gutterBottom
        >
          {t("Profile")}
        </Typography>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <Box
        id="profileBox"
        sx={{
          display: { xs: "block", sm: "flex" },
          width: "100%",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
            }}
          >
            <ProfilePictureSelect
              frmID={"pfpForm"}
              layID={"layPfpSelect"}
              divID={"pfpDiv"}
              fileID={"file"}
              header={"Change Profile Picture"}
              label={"Profile Picture"}
              url={url || FakeUser}
            ></ProfilePictureSelect>
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              gap: "1rem",
              width: "100%",
              marginLeft: ".5rem",
              marginRight: ".5rem",
            }}
          >
            <CustomTextField
              id="txtFirstName"
              label={t("First Name")}
              variant="filled"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "100%%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
            />
            <CustomTextField
              id="txtOrg"
              label={t("Organization")}
              variant="filled"
              value={org}
              disabled
              sx={{
                width: { xs: "100%", sm: "100%%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
            />
            <SelectFields
              title={t("Measurement System")}
              value={measurementSystem}
              fields={[
                { value: "metric", label: "Metric (m,Kg)" },
                { value: "imperial", label: "Imperial  (ft,lbs)" },
              ]}
              onChange={(value) => {
                setMeasurementSystem(value);
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              gap: "1rem",
              width: "100%",
              marginLeft: ".5rem",
              marginRight: ".5rem",
            }}
          >
            <CustomTextField
              id="txtLastName"
              label={t("Last Name")}
              variant="filled"
              value={lName}
              onChange={(e) => setLName(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "100%%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
            />
            <SelectFields
              title={t("Language")}
              value={lang}
              fields={Languages}
              onChange={(value) => {
                handleChangeLanguage(value);
              }}
            />
            <TimezonePicker
              title={t("Time Zone")}
              value={timezone}
              onChange={(value) => {
                setTimezone(value);
              }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "block", sm: "flex" },
              flexDirection: "column",
              gap: "1rem",
              alignSelf: "start",
              width: "100%",
              marginLeft: ".5rem",
              marginRight: ".5rem",
            }}
          >
            <CustomTextField
              id="txtEmail"
              label={t("Email Address")}
              variant="filled"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "100%%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
              error={email !== "" && emailError}
              helperText={emailError ? t("Email not Valid") : null}
              onBlur={(e) => {
                const errorEmail = validateEmail(email);
                if (errorEmail) {
                  // set value displayed in the textbox
                  setEmailError(false);
                } else {
                  setEmailError(true);
                }
              }}
            />
            <CustomTextField
              id="txtMobile"
              label={t("Mobile Number")}
              variant="filled"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              sx={{
                width: { xs: "100%", sm: "100%%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
              type="tel"
              inputProps={{ maxLength: 32 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
            />
            <CustomTextField
              id="txtUserType"
              label={t("User Type")}
              disabled
              variant="filled"
              value={uType}
              sx={{
                width: { xs: "100%", sm: "100%%" },
                borderColor: theme.palette.custom.borderColor,
                borderRadius: "11px",
                backgroundColor: theme.palette.custom.light,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "red",
            color: "white",
            "&:hover": { backgroundColor: "red" },
          }}
          onClick={() => setOpenDeleteAlertDialog(true)}
        >
          {t("Delete Account")}
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.custom.borderColor,
            color: "white",
            '&:hover': {
              backgroundColor: theme.palette.custom.buttonHoverColor,
              color: theme.palette.custom.buttonHoverTextColor,
              boxShadow: 'none',
            },
          }}
          onClick={handleSave}
        >
          {t("Save")}
        </Button>
      </Box>
      <Divider
        sx={{ my: "10px", backgroundColor: theme.palette.custom.borderColor }}
      />
      <AlertDialog
        open={openDeleteAlertDialog}
        onClose={handleCloseDeleteAlertDialog}
        onDoFunction={() => { handleDelete(name, lName, user.profileId); handleCloseDeleteAlertDialog(); }}
        dialogTitle={t('Are You sure You want to delete your profile? This can not be undone!')}
        cancelLabel={t('Cancel')}
        buttonLabel={t('Delete')}
      />
    </Box>
  );
}
