import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#013C59",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#f37721",
    },
  }));
function Loading() {
    return (
        <div style={{ display: "flex", flex: '1 1 0%', flexFlow: "col", justifyContent: "center", alignItems: "center" }} >
            
            <BorderLinearProgress variant='indeterminate' sx={{ width: '192px' }} className="w-192 sm:w-320 max-w-full rounded-2" />
        </div>
    );
}

export default Loading;
