/* eslint import/no-extraneous-dependencies: off */
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

export const setMachineDocumentationData = createAsyncThunk("machineDocumentation/setMachineDocumentationData", async (inputData, {dispatch, getState}) => {
    let machineDocumentation = [];
    if (inputData === null) {
        return null;
    }
    // if (inputData.json&& inputData.json.operation_person_profile_link && inputData.json.operation_person_profile_link.length > 0) {
    // assignUsers = PopulateUsers(inputData.json);
    // }else{
    //     assignUsers = inputData.json
    // }
    machineDocumentation = inputData.json
    if (inputData.newSearchText && inputData.newSearchText.length > 0 && machineDocumentation && machineDocumentation.length > 0) {
        const searchText = inputData.newSearchText.toLowerCase();
        return machineDocumentation.filter((item) =>
          Object.values(item).some((value) =>
            String(value).toLowerCase().includes(searchText)
          )
        );
    }
    return machineDocumentation;
});

export const setMachineDocumentationDataDirectly = createAsyncThunk("machineDocumentation/setMachineDocumentationDataDirectly", async (inputData, {dispatch, getState}) => {
    
    return inputData;
});

const initialState = null;

const machineDocumentationSlice = createSlice({
    name: "machineDocumentation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setMachineDocumentationData.fulfilled, (state, action) => action.payload);
        builder.addCase(setMachineDocumentationDataDirectly.fulfilled, (state, action) => action.payload);
    }
});

export const selectMachineDocumentation = ({machineDocumentation}) => machineDocumentation;

export default machineDocumentationSlice.reducer;
