/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
  localStorage.setItem("user", JSON.stringify(user));  
  return user;
});



const initialState = {
  role: [], // guest
  data: {
    displayName: 'John Doe',
    photoURL: 'assets/images/avatars/brian-hughes.jpg',
    email: 'johndoe@withinpixels.com',
    shortcuts: ['apps.calendar', 'apps.mailbox', 'apps.contacts', 'apps.tasks'],
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(setUser.fulfilled, (state, action) => action.payload);
  },
});

export const selectUser = ({ user }) => user;

export default userSlice.reducer;
