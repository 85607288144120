import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import {
  GetHistoryTimerSumJSON,
  LatestDataColoring,
} from "../utils/systemInfo";
import moment from "moment";
import OpsumService from "../api/opsum";
import StyledColumnBox from "../common/StyledColumnBox";
import TableGreenTypography from "../common/TableGreenTypography";
import StyledTypography from "../common/StyledTypography";
import OpsumTotalsComparisonProgress from "../common/opsumTotalsComparisonProgress";
import OpsumHistoryTotals from "../common/opsumHistoryTotals";
import ViewButton from "../common/viewButton";
import HistoryButton from "../common/historyButton";
import CollapseButton from "../common/collapseButton";

const opsumTotalsComparisonList = [
  {
    id: 0,
    tittle: "Powered",
    valueName: "powered",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "EngineRun",
    valueName: "engine_run",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "Tramming",
    valueName: "tramming",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "EnginerWorking",
    valueName: "high_rpm",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "Forward",
    valueName: "forward",
    valueLabel: "Hours",
    compareReverse: false,
  },
  {
    id: 0,
    tittle: "Reverse",
    valueName: "reverse",
    valueLabel: "Hours",
    compareReverse: false,
  },
];

export default function SafetyTableRow({
  row,
  handleClick,
  handleArrowIcon,
  isItemSelected,
  labelId,
  dateFromBefore,
  dateFrom,
  dateto,
  handleView,
}) {
  const [open, setOpen] = useState(false);
  const [deviceHistoryTotals, setDeviceHistoryTotals] = useState(undefined);
  const [compareDeviceHistoryTotals, setCompareDeviceHistoryTotals] =
    useState(undefined);
  const [productionJson, setProductionJson] = useState(undefined);
  const [scheduledTime, setScheduledTime] = useState(undefined);
  const { t } = useTranslation();
  const theme = useTheme();
  const organziation = useSelector(({ organziation }) => organziation);
  const timezoneMins = useSelector(({ timezone }) => timezone);

  function handleOpenCollapse() {
    if (!open) {
      const apiKey = organziation?.api_key_list?.[0]?.key;
      OpsumService.GetDeviceOpsumTimers({
        deviceID: row.DeviceID,
        deviceType: "nerohub",
        apiKey,
        historyFromDST: dateFrom,
        historyToDST: dateto,
      })
        .then((jsonCurrent) => {
          const DeviceHistoryTotals = GetHistoryTimerSumJSON(
            jsonCurrent.opsum.device[0].data,
            dateFrom,
            dateto
          );
          setDeviceHistoryTotals(DeviceHistoryTotals);
          setProductionJson(jsonCurrent.opsum.device[0].production);
          setScheduledTime(jsonCurrent.scheduled_time.scheduled);
          OpsumService.GetDeviceOpsumTimers({
            deviceID: row.DeviceID,
            deviceType: "nerohub",
            apiKey,
            historyFromDST: dateFromBefore,
            historyToDST: dateFrom,
          })
            .then((jsonPrevious) => {
              if (jsonPrevious) {
                const CompareDeviceHistoryTotals = GetHistoryTimerSumJSON(
                  jsonPrevious.opsum.device[0].data,
                  dateFromBefore,
                  dateFrom
                );
                setCompareDeviceHistoryTotals(CompareDeviceHistoryTotals);
              }
            })
            .catch((errorr) => {
              if (process.env.NODE_ENV === "development")
                console.log("getUserTypePermissionserrorr", errorr);
            });
        })
        .catch((errorr) => {
          if (process.env.NODE_ENV === "development")
            console.log("getUserTypePermissionserrorr", errorr);
        });
    }
    setOpen(!open);
  }
  return (
    <Fragment>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.DeviceName}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
      >
        <TableCell>
          <CollapseButton handleOpenCollapse={handleOpenCollapse} open={open} />
        </TableCell>
        <TableCell
          onClick={(event) => handleClick(event, row.DeviceName)}
          sx={{ height: "100%", color: theme.palette.custom.text }}
          component="th"
          id={labelId}
          scope="row"
          padding="none"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{ width: "40px" }}
              dangerouslySetInnerHTML={{
                __html: row.DeviceTypeIcon.replace(
                  "theme.palette.custom.text",
                  theme.palette.custom.text
                ),
              }}
            />{" "}
            <StyledTypography variant="subtitle2">
              {row.DeviceName}
            </StyledTypography>{" "}
          </Box>
        </TableCell>
        <TableCell
          align="left"
          onClick={(event) => handleClick(event, row.DeviceName)}
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
            }}
          >
            <StyledColumnBox>
              <StyledTypography variant="subtitle2">
                {t("Count")} :&nbsp;{" "}
                <TableGreenTypography variant="subtitle2">
                  {row.TotalSBICrawlCounter
                    ? Number(row.TotalSBICrawlCounter).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp; {t("incidents")}
                </TableGreenTypography>
              </StyledTypography>

              <StyledTypography variant="subtitle2" gutterBottom>
                {t("Time")} :&nbsp;{" "}
                <TableGreenTypography variant="subtitle2">
                  {row.TotalSBICrawlMinutes
                    ? Number(row.TotalSBICrawlMinutes / 60).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp;{t("hours")}
                </TableGreenTypography>
              </StyledTypography>
            </StyledColumnBox>
            <span
              title={`Previous: ${Number(
                row.TotalSBICrawlMinutesLastWeek / 60
              ).toFixed(2)}`}
            >
              {handleArrowIcon(
                row.TotalSBICrawlCounter,
                row.TotalSBICrawlCounterLastWeek,
                true,
                30
              )}
            </span>
          </Box>
        </TableCell>
        <TableCell
          align="left"
          onClick={(event) => handleClick(event, row.DeviceName)}
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
            }}
          >
            <StyledColumnBox>
              <StyledTypography variant="subtitle2">
                {t("Count")} :&nbsp;{" "}
                <TableGreenTypography variant="subtitle2">
                  {row.TotalSBIStopCounter
                    ? Number(row.TotalSBIStopCounter).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp; {t("incidents")}
                </TableGreenTypography>
              </StyledTypography>

              <StyledTypography variant="subtitle2" gutterBottom>
                {t("Time")} :&nbsp;{" "}
                <TableGreenTypography variant="subtitle2">
                  {row.TotalSBIStopMinutes
                    ? Number(row.TotalSBIStopMinutes / 60).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp; {t("hours")}
                </TableGreenTypography>
              </StyledTypography>
            </StyledColumnBox>
            <span
              title={`Previous: ${Number(
                row.TotalSBIStopMinutesLastWeek / 60
              ).toFixed(2)}`}
            >
              {handleArrowIcon(
                row.TotalSBIStopCounter,
                row.TotalSBIStopCounterLastWeek,
                true,
                30
              )}
            </span>
          </Box>
        </TableCell>
        <TableCell
          align="left"
          onClick={(event) => handleClick(event, row.DeviceName)}
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
            }}
          >
            <StyledColumnBox>
              <StyledTypography variant="subtitle2">
                {t("Count")} :&nbsp;{" "}
                <TableGreenTypography variant="subtitle2">
                  {row.TotalCWSCrawlCounter
                    ? Number(row.TotalCWSCrawlCounter).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp; {t("incidents")}
                </TableGreenTypography>
              </StyledTypography>

              <StyledTypography variant="subtitle2" gutterBottom>
                {t("Time")} :&nbsp;{" "}
                <TableGreenTypography variant="subtitle2">
                  {row.TotalCWSCrawlMinutes
                    ? Number(row.TotalCWSCrawlMinutes / 60).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp; {t("hours")}
                </TableGreenTypography>
              </StyledTypography>
            </StyledColumnBox>
            <span
              title={`Previous: ${Number(
                row.TotalCWSCrawlMinutesLastWeek / 60
              ).toFixed(2)}`}
            >
              {handleArrowIcon(
                row.TotalCWSCrawlCounter,
                row.TotalCWSCrawlCounterLastWeek,
                true,
                30
              )}
            </span>
          </Box>
        </TableCell>
        <TableCell
          align="left"
          onClick={(event) => handleClick(event, row.DeviceName)}
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
            }}
          >
            <StyledColumnBox>
              <StyledTypography variant="subtitle2">
                {t("Count")} :&nbsp;{" "}
                <TableGreenTypography variant="subtitle2">
                  {row.TotalCWSBrakeCounter
                    ? Number(row.TotalCWSBrakeCounter).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp; {t("incidents")}
                </TableGreenTypography>
              </StyledTypography>

              <StyledTypography variant="subtitle2" gutterBottom>
                {t("Time")} :&nbsp;{" "}
                <TableGreenTypography variant="subtitle2">
                  {row.TotalCWSBrakeMinutes
                    ? Number(row.TotalCWSBrakeMinutes / 60).toFixed(2)
                    : (0).toFixed(2)}
                  &nbsp;{t("hours")}
                </TableGreenTypography>
              </StyledTypography>
            </StyledColumnBox>
            <span
              title={`Previous: ${Number(
                row.TotalCWSBrakeMinutesLastWeek / 60
              ).toFixed(2)}`}
            >
              {handleArrowIcon(
                row.TotalCWSBrakeCounter,
                row.TotalCWSBrakeCounterLastWeek,
                true,
                30
              )}
            </span>
          </Box>
        </TableCell>
        <TableCell
          align="left"
          onClick={(event) => handleClick(event, row.DeviceName)}
        >
          <TableGreenTypography
            color={LatestDataColoring(row.DeviceLastSeen, true)}
            variant="subtitle2"
          >
            {moment
              .utc(row.DeviceLastSeen)
              .add(timezoneMins, "minutes")
              .format("YYYY-MM-DD HH:mm")}
          </TableGreenTypography>
        </TableCell>
        <TableCell align="center">
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexFlow: "row",
            }}
          >
            <ViewButton
              onClick={(event) => handleView(event, row)}
              // onClick={(event) => handleClick(event, row.name, row.DeviceID)}
            />
            <HistoryButton device={row} />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                sx={{ color: theme.palette.custom.text }}
                variant="h6"
                gutterBottom
                component="div"
              >
                {`${t("OPSUM DATA FOR")}:${row.DeviceName}`}
              </Typography>
              <OpsumHistoryTotals
                opsumTotalsComparisonList={opsumTotalsComparisonList}
                deviceHistoryTotals={deviceHistoryTotals}
                compareDeviceHistoryTotals={compareDeviceHistoryTotals}
                handleArrowIcon={handleArrowIcon}
              />
              <Box sx={{ textAlign: "center", width: "100%" }}>
                {productionJson && scheduledTime ? (
                  // <div style={{ width: '100%',height: '75px' }} dangerouslySetInnerHTML={{
                  //     __html: (GetDeviceOpsumTotalsComparisonProgressHTML(productionJson, scheduledTime))
                  // }} />
                  <OpsumTotalsComparisonProgress
                    ProductionJson={productionJson}
                    scheduled_time={scheduledTime}
                  />
                ) : null}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
